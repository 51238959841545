@import '../../styles/vars.scss';

@mixin for-mobile {
    @media screen and (max-width: 600px) {
        @content;
    }
}

@mixin for-middle {
    @media screen and (min-width: 600.1px) and (max-width: 1250px) {
        @content;
    }
}

@mixin for-middle1 {
    @media screen and (min-width: 1100.1px) and (max-width: 1250px) {
        @content;
    }
}

@mixin for-middle2 {
    @media screen and (min-width: 600.1px) and (max-width: 1100px) {
        @content;
    }
}

.accountTypes {
    width: 100%;
    @include flex(center, start);
    flex-direction: column;
    background-color: #242529;

    .header {
        margin-top: 80px;
        width: 100%;
        @include flex(start, center);
        flex-direction: column;
        border-radius: 32px;
        padding: 0 80px;
        gap: 24px;

        @include for-mobile {
            margin-top: 0;
            padding: 48px 16px;

        }

        @include for-middle {
            margin-top: 0;
            padding: 48px 5%;
        }

        h1 {
            color: #FFF;
            font-family: 'Manrope';
            font-size: 72px;
            font-style: normal;
            font-weight: 500;
            line-height: 84px;
            margin: 0;
        }

        p {
            color: #FFF;
            font-family: 'Manrope';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin: 0;
        }
    }

    .accountTypes_mobile {
        display: none;
    }

    .accountTypes_block {
        padding: 80px;
        width: 100%;
        @include flex(center, center);

        .typesBody {
            @include flex(center, start);
            flex-direction: column;
            width: 100%;

            .types_header {
                width: 100%;
                height: 180px;
                @include flex(stretch, center);
                gap: 9px;
                margin-bottom: 32px;

                @include for-middle {
                    height: 100%;
                }

                .header_name {
                    flex: 1;
                    @include flex(start, end);
                    flex-direction: column;
                    gap: 8px;
                    padding-bottom: 0;
                    padding-left: 0;
                    padding-right: 24px;

                    p {
                        color: #51525C;
                        font-family: 'Manrope';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        margin: 0;
                    }

                    h1 {
                        margin: 0;
                        color: #FFF;
                        text-align: center;
                        font-family: 'Manrope';
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                .type {
                    padding: 24px;
                    flex: 1;
                    border-radius: 32px;
                    @include flex(center, space-between);
                    flex-direction: column;
                    cursor: pointer;
                    border-radius: 8px;
                    border: 1px solid #2D2E32;

                    .name {
                        color: #FFF;
                        text-align: center;
                        font-family: 'Satoshi';
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;
                        margin: 0;
                    }

                    .center {
                        @include flex(center, center);
                        flex-direction: column;
                        gap: 8px;

                        p {
                            color: #FFF;
                            text-align: center;
                            font-family: 'Satoshi';
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 32px;
                            margin: 0;
                        }

                        span {
                            color: #A0A0AB;
                            text-align: center;
                            font-family: 'Satoshi';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 24px;
                        }
                    }

                    a {
                        border-radius: 8px;
                        border: 1px solid rgba(255, 255, 255, 0.08);
                        background: #303034;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                        display: flex;
                        padding: 12px;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        align-self: stretch;
                        color: #FFF;
                        font-family: 'Inter';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }
            }

            .rows {
                margin-top: 12px;
                width: 100%;
                @include flex(center, start);
                flex-direction: column;

                .types_row {
                    width: 100%;
                    @include flex(stretch, center);
                    gap: 9px;
                    border-bottom: 1px solid var(--Border-Main, #26272B);

                    .row_name {
                        flex: 1;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        color: #51525C;
                        text-align: center;
                        font-family: 'Manrope';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        text-align: start;
                        margin: 0;
                    }

                    .value {
                        padding-top: 12px;
                        padding-bottom: 12px;
                        @include flex(center, center);
                        flex: 1;

                        p {
                            color: #FFF;
                            text-align: center;
                            font-family: 'Manrope';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 16px;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .accountTypes {
        .header {
            text-align: start;

            h1 {
                color: var(--Global-Dark-25, #FCFCFC);
                font-size: 40px;
                font-weight: 500;
                line-height: normal;
            }

            p {
                font-size: 14px
            }

        }

        .accountTypes_mobile {
            @include flex(center, start);
            flex-direction: column;
            padding: 16px;
            border-radius: 32px;
            background: var(--Background-2, #18181B);
            margin-top: 48px;
            width: calc(100% - 64px);

            .top {
                width: 100%;
                @include flex(center, start);
                gap: 32px;
                flex-direction: column;

                .name_buttons {
                    width: 100%;
                    @include flex(center, space-between);

                    p {
                        color: #FFF;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 24px;
                    }

                    .prev_next {
                        @include flex(center, center);
                        padding: 16px;
                        border-radius: 9999px;
                        background: var(--Background-3, #1D1D20);
                    }
                }

                .center {
                    @include flex(center, center);
                    flex-direction: column;
                    gap: 8px;

                    p {
                        margin: 0;
                        color: #FFF;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 32px;
                    }

                    span {
                        color: var(--Global-Dark-400, #A0A0AB);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }

                a {
                    display: flex;
                    padding: 12px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    align-self: stretch;
                    color: var(--Nav_Button-Font_Active, #FCFCFC);
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    border-radius: 9999px;
                    background: var(--Background-3, #1D1D20);
                    text-decoration: none;
                }
            }

            .rows {
                width: 100%;
                @include flex(center, start);
                flex-direction: column;

                .row {
                    width: 100%;
                    @include flex(stretch, center);
                    border-bottom: 1px solid var(--Border-Main, #26272B);

                    p {
                        text-align: start;
                        flex: 1;
                        padding-top: 24px;
                        padding-bottom: 24px;
                        color: var(--Global-Dark-400, #A0A0AB);
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                    }

                    .value {
                        @include flex(center, center);
                        flex: 1;
                        padding-top: 24px;
                        padding-bottom: 24px;
                        color: var(--Global-Dark-25, #FCFCFC);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 16px;
                    }
                }

                .row:last-child {
                    border: none;
                }
            }
        }

        .accountTypes_block {
            display: none;
        }
    }
}

@media (min-width: 600.1px) and (max-width: 1100px) {
    .accountTypes {
        .header {
            text-align: start;
            background-size: cover;
            background-position: bottom;

            h1 {
                color: var(--Global-Dark-25, #FCFCFC);
                font-size: 48px;
                font-weight: 500;
                line-height: normal;
            }

            p {
                width: 329px;
            }

        }

        .accountTypes_mobile {
            @include flex(center, start);
            flex-direction: column;
            padding: 16px;
            border-radius: 12px;
            background: var(--Background-2, #18181B);
            margin-top: 48px;
            width: calc(100% - 64px);

            .top {
                width: 100%;
                @include flex(center, start);
                gap: 32px;
                flex-direction: column;

                .name_buttons {
                    width: 100%;
                    @include flex(center, space-between);

                    p {
                        color: #FFF;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 24px;
                    }

                    .prev_next {
                        @include flex(center, center);
                        padding: 16px;
                        border-radius: 9999px;
                        background: var(--Background-3, #1D1D20);
                    }
                }

                .center {
                    @include flex(center, center);
                    flex-direction: column;
                    gap: 8px;

                    p {
                        margin: 0;
                        color: #FFF;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 32px;
                    }

                    span {
                        color: var(--Global-Dark-400, #A0A0AB);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }

                a {
                    display: flex;
                    padding: 12px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    align-self: stretch;
                    color: var(--Nav_Button-Font_Active, #FCFCFC);
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    background: var(--Background-3, #1D1D20);
                    border-radius: 8px;
                    text-decoration: none;
                }
            }

            .rows {
                width: 100%;
                @include flex(center, start);
                flex-direction: column;

                .row {
                    width: 100%;
                    @include flex(stretch, center);
                    border-bottom: 1px solid var(--Border-Main, #26272B);

                    p {
                        text-align: start;
                        flex: 1;
                        padding-top: 24px;
                        padding-bottom: 24px;
                        color: var(--Global-Dark-400, #A0A0AB);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 16px;
                    }

                    .value {
                        @include flex(center, center);
                        flex: 1;
                        padding-top: 24px;
                        padding-bottom: 24px;
                        color: var(--Global-Dark-25, #FCFCFC);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 16px;
                    }
                }

                .row:last-child {
                    border: none;
                }
            }
        }

        .accountTypes_block {
            display: none;
        }
    }
}