@import '../../styles/vars.scss';
// @import '../../styles/theme.scss';

@mixin for-mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}
@mixin for-middle {
  @media screen and (min-width: 600.1px) and (max-width: 1200px) {
    @content;
  }
}

.page {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #242529;

  @include for-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @include for-middle {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.header {
    margin-top: 12px;
    width: 100%;
    @include flex(start, center);
    flex-direction: column;
    gap: 24px;
    padding: 80px;
  
    @include for-mobile {
      margin-top: 0;
      padding: 48px 16px;
    }

    @include for-middle {
      margin-top: 0;
      padding: 48px 5%;
    }
  
    h1 {
      color: #FFF;
font-family: 'Manrope';
font-size: 72px;
font-style: normal;
font-weight: 500;
line-height: 84px;
      margin: 0;
  
      @include for-mobile {
          text-align: start;
          font-size: 32px;
          font-style: normal;
          hyphens: auto;
          word-wrap: break-word;
      }

      @include for-middle {
        text-align: start;
        hyphens: auto;
        word-wrap: break-word;
      }
    }
  
    p {
      color: #FFF;
font-family: 'Manrope';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
      margin: 0;
  
      @include for-mobile {
        text-align: start;
      }

      @include for-middle {
        text-align: start;
      }
    }
  }

  .info {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @include for-mobile {
      width: 100%;
    }
  }
  
  .info__container {
    width: 100%;
    padding: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #51525C;

    @include for-mobile {
      padding: 48px 16px;
    }

    @include for-middle {
      padding: 48px 5%;
    }

    h1 {
      color: #FFF;
font-family: 'Manrope';
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: 32px;
margin: 0;
margin-bottom: 32px;

      @include for-mobile {
        font-size: 32px;
        text-align: start;
    }

      @include for-middle {
        text-align: start;
    }
    }

    p{
      color: #FFF;
font-family: 'Manrope';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px;
text-align: start;

      @include for-mobile {
        font-size: 14px;
    }
    }
  }

  .info__container:first-child {
    border-top: 1px solid #51525C;
  }

  .info__container_list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    text-align: start;

    @include for-mobile {
      margin-left: 0;
    }

    @include for-middle {
      align-self: center;
    }

    p {
      align-self: flex-start;
    }

    li {
      color: var(--Font-Mine, #FCFCFC);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: start;
      margin-left: 20px;

      @include for-mobile {
        width: 80%;
        margin-left: 7%;
        font-size: 14px;
      }

      @include for-middle {
        width: 80%;
      }
    }
  }

