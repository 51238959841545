@import './theme.scss';
@import './vars.scss';

@mixin for-mobile {
    @media screen and (max-width: 600px) {
        @content;
    }
}

.topbar {
    width: 100%;
    height: 5.7vh;
    // border-radius: 9999px;
    border: 1px solid var(--border-main, #26272B);
    background: var(--background-1, #18181B);
    @include flex(center, space-between);

    .mobile_navbar {
        display: none;
    }

    .logo {
        padding: 0.8vh;
        @include flex(center, start);
        gap: 1.3vh;

        img {
            width: 3.95vh;
            height: 4.1vh;
        }

        h2 {
            color: var(--font-mine, #1F2937);
            font-size: 1.8vh;
            font-weight: 500;
            line-height: 2.4vh;
        }
    }

    .right_side {
        width: 55.8vh;
        @include flex(stretch, stretch);
        gap: 2.4vh;

        .balance {
            border-right: 1px solid var(--border-main, #E5E7EB);
            border-left: 1px solid var(--border-main, #E5E7EB);
            padding-left: 3.2vh;
            flex: 3;
            height: 56px;
            padding-right: 3.2vh;
            @include flex(center, center);

            .inside_balance {
                @include flex(start, start);
                flex-direction: column;

                p {
                    margin: 0;
                    color: var(--font-mine, #1F2937);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }

                span {
                    color: var(--font-sub-font, #9CA3AF);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    letter-spacing: 0.24px;
                }
            }
        }

        .buttons {
            @include flex(center, end);
            gap: 0.8vh;
            padding: 0.8vh;

            .connect_wallet{
                @include flex(center,center);
                padding: 0.4vh;
                background: var(--button-alternative-default, #303034);
                border: 1px solid var(--border-button);
                border-radius: 1000px;
                outline: none;
            }

            .language_dropdown {
                z-index: 1000;
                display: flex;
                flex-direction: column;
                background: var(--button-alternative-default, #303034);
                border: 1px solid rgba(255, 255, 255, 0.24);
                position: absolute;
                right: 115px;
                top: 6.2vh;
                border-radius: 8px;
                width: 57px;

                span {
                    cursor: pointer;
                    gap: 1.2vh;
                    transition: all 0.15s ease-in-out;
                    color: var(--nav-button-font-active) !important;
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    padding: 12px;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                }
            }


            .theme {
                @include flex(center, center);
                cursor: pointer;

                .button {
                    border-radius: 8px;
                    border: 1px solid var(--border-button, rgba(255, 255, 255, 0.08));
                    background: var(--button-alternative-default, #303034);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                    display: flex;
                    padding: 8px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;

                    svg {
                        path {
                            fill: var(--font-mine);
                        }
                    }
                }

                // .moon{
                //     padding: 1vh;
                //     @include flex(center,center);
                //     path{
                //         fill: var(--font-sub-font);
                //     }
                //     &.active{
                //         path{
                //             fill:  var(--font-mine);
                //         }
                //     }
                // }
                // .sun{
                //     padding: 1vh;
                //     @include flex(center,center);
                //     path{
                //         fill: var(--font-sub-font);
                //     }
                //     &.active{
                //         path{
                //             fill:  var(--font-mine);
                //         }
                //     }
                // }
            }

            .lang_currency {
                @include flex(center, center);
                padding: 8px;
                gap: 1.2vh;
                border-radius: 8px;
                border: 1px solid var(--border-button, rgba(255, 255, 255, 0.08));
                background: var(--button-alternative-default, #303034);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

                .lang {
                    @include flex(center, center);
                    cursor: pointer;

                    path {
                        stroke: var(--font-mine);
                    }
                }

                p {
                    color: var(--font-mine);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    margin: 1px;
                    margin-right: 8px;
                }

                .border {
                    @include flex(center, center);

                    path {
                        stroke: var(--border-main);
                    }
                }

                .currency {
                    @include flex(center, center);
                    cursor: pointer;
                    color: var(--nav-button-font-active);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
            }

            .logout {
                @include flex(center, start);
                cursor: pointer;
                gap: 1.2vh;
                transition: all 0.15s ease-in-out;
                color: var(--nav-button-font-active, #FCFCFC);
                font-size: 1.4vh;
                font-weight: 500;
                line-height: 1.6vh;
                border-radius: 8px;
                padding: 8px 20px;
                border: 1px solid rgba(255, 255, 255, 0.24);
                background: var(--button-mine-default, #3B82F6);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

            }

            .logout:hover {
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.24);
                background: var(--button-mine-hover, #458CFF);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
            }
        }
    }
}


@media (max-width: 600px) {
    .topbar {
        width: 100%;
        background: none;
        border-bottom: 1px solid var(--border-main, #2D2E32) !important;
        border: none;
        padding: 16px;

        .mobile_navbar {
            @include flex(center, center);
            width: 4vh;
            height: 4vh;
            border-radius: 8px;
            background: var(--buttons-alternative-default);

            path {
                stroke: var(--font-mine);
            }
        }

        .logo {
            display: none;
        }
        .right_side {
            width: max-content;
            justify-content: end;
            // margin-right: 20px;

            .balance {
                display: none;
            }

            .buttons {
                padding: 0;
                .theme {
                    display: none;
                }

                .lang_currency {
                    display: none;
                }
            }
        }
    }

    .selectButton {
        right: 16px;
        top: 45px !important;
        width: 150px !important;
        z-index: 1000;
    }
}

.selectButton {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 6.2vh;
    border-radius: 8px;
    border: 1px solid var(--border-main, #2D2E32);
    background: var(--border-main, #242529);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    width: 11.2vw;

    button {
        border: none;
        padding: 12px 16px 12px 12px;
        gap: 12px;
        display: flex;
        color: var(--font-mine, #FFF);
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        background: var(--border-main, #242529);
        border-radius: 8px;
        align-items: center;

        svg {
            path {
                stroke: var(--font-mine);
            }
        }

        &:hover {
            background: var(--border-main, #2D2E32);
        }
    }
}

.account_drawer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid var(--border-main, #2D2E32);
    background: var(--background-2, #242529);
    height: 94.3vh;
    margin-top: 5.7vh;

    @include for-mobile {
        // width: 100vw;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid var(--border-main, #2D2E32);

        @include for-mobile {
            min-width: 43vh;
        }

        .left_side {
            display: flex;
            align-items: center;
            gap: 12px;

            div {
                display: flex;
                padding: 8px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                background: var(--border-main, #2D2E32);
            }

            p {
                color: var(--font-mine, #FCFCFC);
                font-family: 'Inter';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.28px;
            }
        }

        .right_side {
            display: flex;
            padding: 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.08);
            background: var(--button-alternative-default, #303034);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

            @include for-mobile {}
        }
    }

    .body {
        display: flex;
        padding: 16px;
        flex-direction: column;

        label {
            color: var(--text-sub, #51525C);
            font-family: 'Inter';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.24px;
            margin-bottom: 12px;
        }

        input {
            display: flex;
            padding: 12px;
            align-items: center;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid var(--border-main, #2D2E32);
            background: var(--background-1, #1C1D21);
            color: var(--font-mine, #FCFCFC);
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.28px;
            margin-bottom: 36px;
        }
    }

    .footer {
        display: flex;
        gap: 8px;
        padding: 16px;

        button {
            flex: 1;
            display: flex;
            padding: 12px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.24);
            background: var(--brand-mine, #3B82F6);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
            color: var(--text-mine, #FFF);
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        button:first-child {
            border: 1px solid var(--border-button, rgba(255, 255, 255, 0.08));
            background: var(--button-alternative-default, #303034);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
        }
    }
}
.account_drawer {
    width: calc(50.6vh - 3.6vh);
    background-color: var(--background-2);
    position: relative;
    // border-radius: 32px;
    border: 1px solid var(--border-main);
    @include flex(center, space-between);
    flex-direction: column;
    height: 100vh;

    .top_drawer{
        width: 100%;
        @include flex(center,start);
        flex-direction: column;
        .drawer_header {
            width: calc(100% - 3.2vh);
            border-bottom: 1px solid var(--border-main);
            padding: 1.6vh;
            color: var(--font-mine);
            font-size: 1.4vh;
            font-weight: 600;
            line-height: 1.6vh;
            letter-spacing: 0.28px;
            @include flex(center, space-between);
            gap: 1.2vh;

            .closeButton {
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.08);
                background: var(--button-alternative-default, #303034);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                padding: 12px;
                align-self: flex-end;
            }

            div {
                @include flex(center, center);
                gap: 12px;

                .icon {
                padding: 0.8vh;
                border-radius: 8px;
                @include flex(center, center);
                background: var(--border-main, #26272B);
    
                ellipse {
                    stroke: var(--font-mine);
                }
            }
            }
    
            
        }
        .alredyExists{
            width: 100%;
            @include flex(center,center);
            flex-direction: column;
            gap: 3vh;
            h3{
                text-align: center;
                color: var(--font-mine);
            }
            button{
                flex: 1;
                @include flex(center,center);
                border-radius: 8px;
                border: none;
                padding: 1.6vh 4.8vh;
                font-family: $font;
                color: var(--global-dark-25);
                font-size: 1.4vh;
                font-weight: 500;
                line-height: 1.6vh;
                background: var(--brand-mine, #3B82F6);
                cursor: pointer;
            }
            button:hover{
                transition: all 0.15s ease-in-out;
                background: var(--brand-mine, #3B82F6);
                box-shadow: 0px 0px 8px 2px rgba(105, 79, 214, 0.32);
            }
        }
        
        .drawer_body {
            width: calc(100%);
            margin-top: 1.6vh;
            @include flex(center, start);
            flex-direction: column;
            gap: 2.4vh;
            .wallet_types{
                width: 100%;
                gap: 1.6vh;
                @include flex(center,start);
                flex-direction: column;
                .type{
                    cursor: pointer;
                    .icon{
                        @include flex(center,center);
                    }
                    width: calc(100% - 2.4vh);
                    padding: 1.2vh;
                    border-radius: 16px;
                    border: 1px solid var(--border-main);
                    @include flex(center,start);
                    gap: 0.8vh;
                    color: var(--font-mine);
                    &.active{
                        border-color: #3B82F6;
                    }
                }
            }
            .words_inputs{
                width: 100%;
                gap: 1.6vh;
                @include flex(center,start);
                flex-direction: column;
                span{
                    width: calc(100% - 2.4vh);
                    color: var(--font-mine);
                }
                .three_inputs{
                    width: calc(100% - 2.4vh);
                    @include flex(stretch,stretch);
                    gap: 0.6vh;
                    input{
                        width: 33%;
                        flex: 0.333;
                        background: none;
                        border: 1px solid rgba(255, 255, 255, 0.08);
                        padding: 0.6vh 0.8vh;
                        border-radius: 8px;
                        color: var(--font-mine);
                        font-family: "Inter Tight", sans-serif;
                        margin: 0;
                    }
                    input:focus {   
                        border-color: #3B82F6;
                        // box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px rgba(229, 103, 23, 0.6);
                        outline: 0 none;
                    }
                }
            }
        }
    }
    .drawer_footer{
        width: calc(100% - 3.2vh);
        padding: 1.6vh;
        @include flex(center,center);
        gap: 0.8vh;
        border-top: 1px solid var(--border-main);
        div{
            flex: 1;
            @include flex(center,center);
            border-radius: 8px;
            background: var(--buttons-alternative-default);
            color: var(--nav-button-font-active);
            font-size: 1.4vh;
            padding: 1.6vh;
            font-weight: 500;
            line-height: 1.6vh;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
        }
        div:hover{
            background: var(--buttons-alternative-hover);
        }
        button{
            flex: 1;
            @include flex(center,center);
            border-radius: 8px;
            border: none;
            padding: 1.6vh;
            font-family: $font;
            color: var(--global-dark-25);
            font-size: 1.4vh;
            font-weight: 500;
            line-height: 1.6vh;
            background: var(--brand-mine, #3B82F6);
            cursor: pointer;
        }
        button:hover{
            transition: all 0.15s ease-in-out;
            background: var(--brand-mine, #3B82F6);
            box-shadow: 0px 0px 8px 2px rgba(105, 79, 214, 0.32);
        }
    }
}

@media (max-width: 600px) {
    .paper{
        padding: 0;
        width: 100%;
    }
    .account_drawer{
        width: calc(100% - 2px);
        .close_icon{
            display: none;
        }
    }
}