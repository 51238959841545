@import '../../styles/vars.scss';


.page {
    @include flex(center, center);
    width: 100%;
    min-height: 100vh;
    // background: var(--login-bg-svg);
    background-color: var(--background-2, #242529);
    background-repeat: no-repeat;
    background-size: cover;

    .buttons {
        @include flex(center, end);
        gap: 0.8vh;
        padding: 0.8vh;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .login {
        padding: 20px;
        border-radius: 6px;
        width: 76%;
        @include flex(center, center);
        flex-direction: row;
        flex-wrap: nowrap;

        .login_form {
            padding: 20px;
            border-radius: 6px;
            width: 50%;
            height: 79vh;
            @include flex(center, space-between);
            flex-direction: column;

            .logo {
                @include flex(center, start);
                flex-direction: column;
                gap: 2.28vh;
                color: var(--font-mine);
                font-family: $font;
                font-size: 2.0vh;
                font-weight: 500;
                line-height: 2.4vh;
                text-transform: uppercase;
            }

            .body {
                width: 48%;
                margin-top: 20px;
                @include flex(center, center);
                flex-direction: column;
                gap: 4.8vh;

                .sign_in {
                    @include flex(center, start);
                    flex-direction: column;
                    gap: 1.6vh;

                    h1 {
                        margin: 0;
                        color: var(--font-mine);
                        text-align: center;
                        font-size: 4.0vh;
                        font-weight: 500;
                        line-height: 4.0vh;
                        letter-spacing: -1.2px;
                    }

                    p {
                        margin: 0;
                        color: var(--font-sub-font);
                        text-align: center;
                        font-size: 1.4vh;
                        font-weight: 500;
                        line-height: 1.6vh;
                    }
                }

                .inputs {
                    @include flex(center, center);
                    flex-direction: column;
                    gap: 1.6vh;
                    width: 80%;

                    .Login {
                        width: 100%;
                        @include flex(start, start);
                        flex-direction: column;
                        gap: 1.2vh;

                        label {
                            color: var(--font-sub-font);
                            font-size: 1.2vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                            letter-spacing: 0.24px;
                        }

                        input {
                            width: 100%;
                            border-radius: 8px;
                            border: 1px solid var(--border-main, #26272B);
                            background: var(--input-bg);
                            outline: none;
                            color: var(--font-mine, #FCFCFC);
                            font-family: $font;
                            font-size: 1.4vh;
                            font-weight: 500;
                            padding: 1.6vh;
                            line-height: 1.6vh;
                        }

                        .errorLogin {
                            color: #F63D68;
                            font-weight: 600;
                            font-size: 12px;
                        }
                    }

                    .Password {
                        width: 100%;
                        position: relative;
                        @include flex(start, start);
                        flex-direction: column;
                        gap: 1.2vh;

                        label {
                            color: var(--font-sub-font, #51525C);
                            font-size: 1.2vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                            letter-spacing: 0.24px;
                        }

                        .input_with_icon {
                            width: 100%;
                            @include flex(center, start);
                            border-radius: 8px;
                            border: 1px solid var(--border-main, #26272B);
                            background: var(--input-bg);
                            padding: 0.8vh 0.8vh 0.8vh 1.6vh;

                            input {
                                width: 100%;
                                outline: none;
                                background: none;
                                border: none;
                                color: var(--font-mine);
                                font-family: $font;
                                font-size: 1.4vh;
                                font-weight: 500;
                                line-height: 1.6vh;
                                /* 114.286% */
                            }

                            .icon {
                                @include flex(center, center);
                                padding: 0.8vh;
                                // border-radius: 8px;
                                // background: var(--input-dropdown-button, #26272B);
                                border-left: #242529;

                                path {
                                    fill: var(--font-mine);
                                }
                            }

                        }

                        .errorPassword {
                            color: #F63D68;
                            font-weight: 600;
                            font-size: 12px;
                        }
                    }
                }

                .Button {
                    width: 80%;
                    @include flex(center, center);

                    button {
                        width: 100%;
                        padding: 1.6vh 1.2vh;
                        border-radius: 8px;
                        background: #3B82F6;
                        border: none;
                        font-size: 1.4vh;
                        font-weight: 500;
                        line-height: 1.6vh;
                        font-family: $font;
                        color: #fff;
                        cursor: pointer;
                    }
                }

                .DisableButton {
                    width: 80%;
                    @include flex(center, center);

                    button {
                        width: 100%;
                        padding: 1.6vh 1.2vh;
                        border-radius: 8px;
                        background: #3B82F6;
                        border: none;
                        font-size: 1.4vh;
                        font-weight: 500;
                        line-height: 1.6vh;
                        font-family: $font;
                        color: #fff;
                        cursor: pointer;
                    }
                }

                .Error {
                    .errorText {
                        font-weight: 500;
                        color: #F63D68;
                    }
                }
            }

            .Register {
                width: 100%;
                @include flex(center, center);
                flex-direction: column;

                p {
                    margin-top: 0;
                    margin-bottom: 8px;
                    color: var(--font-sub-font);
                    text-align: center;
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }

                a {
                    text-decoration: none;
                    cursor: pointer;
                    border-radius: 8px;
                    color: var(--nav-button-font-active);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    background: var(--buttons-alternative-default);
                    padding: 0.8vh 1.6vh;
                    transition: all 0.15s ease-in-out;
                }

                a:hover {
                    background: var(--buttons-alternative-hover);

                }
            }
        }

        .bg_laptop {
            width: 50%;
        }
    }
}

@media (max-width: 600px) {
    .page {
        .login {
            .login_form {
                width: 100%;

                .body {
                    width: 80%;
                }
            }

            .bg_laptop {
                display: none;
            }
        }

    }
}