@import './vars.scss';

.header_cell{
    display: flex;
    align-items: center;
    gap: 0.4vh;
    .header_name{
        display: block !important;
    }
}
.paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #1D1D20;
    border-radius: 8px;
}

.paginationButton {
    background-color: #3F3F46;
    color: #FCFCFC;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover:not(:disabled) {
        background-color: #70707B;
    }
}
.customHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--client-table-bg-color);
    height: 4vh;
    width: 100%;
    
    .scrollButton{
        @include flex(center,center);
        padding: 1.2vh 1.5vh;
        border: 1px solid var(--client-table-filter-button-br-color);
        border-radius: 50%;
        background: none;
        cursor: pointer;
        transition: all 0.15s ease-in-out
        path{
            stroke:var(--client-table-filter-icon-color);
        }
    }
    .leftsideToolbar{
        @include flex(center,center);
        gap: 0.8vh;
        .scrollButton:hover{
            background-color: var(--client-table-filter-button-bg-hover-color);
        }
        .filterButton{
            .icon{
                @include flex(center,center);
                path{
                    stroke:var(--client-table-filter-icon-color);
                }
            }
            margin: 0;
            padding: 1.2vh;
            @include flex(center,center);
            gap: 0.8vh;
            color: var(--client-table-filter-button-color);
            text-align: center;
            font-family: $font;
            font-size: 1.4vh;
            line-height: 1.6vh;
            letter-spacing: -0.26px;
            border-radius: var(--Corner-Full, 1000px);
            border: 1px dashed var(--client-table-filter-button-br-color);
            background: none;
            cursor: pointer;
        }
        .filterButtonActive{
            .icon{
                @include flex(center,center);
                path{
                    stroke:var(--client-table-filter-icon-color);
                }
            }
            margin: 0;
            padding: 1.2vh;
            @include flex(center,center);
            gap: 0.8vh;
            color: var(--client-table-filter-button-color);
            text-align: center;
            font-family: $font;
            font-size: 1.4vh;
            line-height: 1.6vh;
            letter-spacing: -0.26px;
            border-radius: var(--Corner-Full, 1000px);
            border: 1px dashed var(--client-table-filter-button-br-color-active);
            background: none;
            cursor: pointer;
        }
        .filterButton:first-of-type {
            border: 1px solid var(--client-table-filter-button-br-color);
        }
        .filterButton:hover{
            background-color: var(--client-table-filter-button-bg-hover-color);
        }
    }
    .rightsideToolbar{
        @include flex(center,center);
        gap: 0.8vh;
        .filterButton{
            .icon{
                @include flex(center,center);
                path{
                    stroke:var(--client-table-filter-icon-color);
                }
            }
            padding: 1.2vh;
            @include flex(center,center);
            gap: 0.8vh;
            color: var(--client-table-filter-button-color);
            text-align: center;
            font-family: $font;
            font-size: 1.4vh;
            line-height: 1.6vh;
            letter-spacing: -0.26px;
            border-radius: var(--Corner-Full, 1000px);
            border: 1px solid var(--client-table-filter-button-br-color);
            background: none;
            cursor: pointer;
        }
        
        .filterButton:hover{
            background-color: var(--client-table-filter-button-bg-hover-color);
        }
        .filterButtonSettings{
            .icon{
                @include flex(center,center);
                path{
                    fill:var(--client-table-filter-icon-color);
                }
            }
            padding: 1.2vh;
            @include flex(center,center);
            gap: 0.8vh;
            color: var(--client-table-filter-button-color);
            text-align: center;
            font-family: $font;
            font-size: 1.4vh;
            line-height: 1.6vh;
            letter-spacing: -0.26px;
            border-radius: var(--Corner-Full, 1000px);
            border: 1px solid var(--client-table-filter-button-br-color);
            background: none;
            cursor: pointer;
        }
        .filterButtonSettings:hover{
            background-color: var(--client-table-filter-button-bg-hover-color);
        }
    }
}

.mui_table {
    width: max-content; /* Даем таблице возможность расширяться */
    max-width: 85.5vw;
    // background-color: var(--background-2)!important;
    font-family: 'Inter', sans-serif !important;
    border-spacing: 0 0.8vh;
    border-collapse: separate !important;
    box-shadow: none;
    position: relative;
    overflow-x: auto; /* Добавляем горизонтальный скролл для всей таблицы */
    white-space: nowrap; /* Предотвращаем перенос текста внутри таблицы */
    min-width: 100%; /* Минимальная ширина таблицы для обеспечения скроллинга */
    .noResultsFound{
        font-style: normal;
        font-family: $font;
        color: var(--font-mine);
    }
    caption {
        display: none;
        width: calc(100% - 2.4vh);
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background-color: var(--client-table-bg-color);
        padding: 0.8vh 1.2vh;
        text-align: left;
        font-weight: bold;
        font-size: 1.6vh;
        color: var(--client-table-head-text-color);
        white-space: nowrap;
    }

    thead {
        z-index: 9;
        background-color: var(--client-table-head-bg-color);
        width: 100%;
        tr{
            th {
                border-bottom: solid var(--border-main, #2D2E32) !important;
            }
        }
    }

    tbody {
        display: table-row-group;
        tr {
            margin-top: 0.8vh;
            td{
                border-bottom: solid var(--border-main, #2D2E32) !important;
                max-height: 4vh;
                color: var(--font-mine);
                font-size: 1.4vh;
                font-weight: 600;
                line-height: 1.6vh;
                letter-spacing: 0.28px;
            }

            td:first-of-type, td:last-of-type {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
    }

}
.mui_table_head_row {
    background: none !important;
    box-shadow: none !important;
    
}

.table_header_cell {
    font-family: $font !important;
    background: none !important;
    border: none !important;
    padding: 1.2vh 1.6vh 1.2vh 1.2vh !important;
    font-size: 1.2vh !important;
    color: var(--client-table-head-text-color) !important;
    line-height: 1.6vh !important;
    margin: 0 !important;
    box-shadow: none !important;
    hr{
        border-width: 1px;
        border-color: var(--pagination-button-bg-color) !important;
    }

    span{
        span{
            display: none !important;
        }
    }
    &:last-of-type{
        justify-content: flex-end;
    }
    &:hover  {
        background: var(--pagination-button-bg-color) !important;
        box-shadow: none;
    }
    &:first-of-type {
        padding: 1.2vh 1.2vh 1.2vh 1.2vh !important;
    }

    
    button{
        margin-left: 32px;
        svg{
            fill:#3F3F46;
        }
    }
    .MuiBadge-root {
        display: none !important;
    }

    .MuiTableSortstatus-icon {
        display: none !important;
    }
    .MuiSvgIcon-root{
        display: none !important;
    }
}

.mui_table_body {
    background: none !important;
    display: table-row-group;
}

.mui_table_body_row {
    background: none !important;
    background: var(--client-table-bg-color) !important;
    
    box-shadow: none;
    td:after{
        background: none !important;
    }
    &:hover td:after {
        background: var(--client-table-head-bg-color) !important;
        box-shadow: none;
    }
    &:hover {
        
        .actionButtons {
            opacity: 1;
        }
    }
    .actionButtons {
        display: flex;
        gap: 10px;
        opacity: 0;
        transition: opacity 0.3s ease;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .actionButtons button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
    }
    
    .actionButtons button svg {
        stroke: #70707B;
        width: 16px;
        height: 16px;
    }
    
    .actionButtons button:hover svg {
        stroke: #52A5E5;
    }
}

.table_body_cell {
    font-family: $font !important;
    border: none !important;
    padding: 1.2vh 1.6vh 1.2vh 1.2vh !important;

    &:first-of-type {
        border-top-left-radius: 13.6vh !important;
        border-bottom-left-radius: 13.6vh !important;
    }

    &:last-of-type {
        border-top-right-radius: 13.6vh !important;
        border-bottom-right-radius: 13.6vh !important;
        // text-align: right;
    }
}
.MuiTableSortLabel-root{
    display: none !important;
}

.table {
    height: 80.8vh;
    width: calc(100% - 4.2vh);
    padding: 1.6vh;
    margin-bottom: 0.4vh;
    background-color: var(--background-2);
    border-radius: 3.2vh;

    .MuiPaper-root {
        box-shadow: none !important;
        overflow: hidden;
    }
}
.table{
    width: 100%;
}
