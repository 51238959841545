@import '../../styles/vars.scss';
@import '../../styles/theme.scss';


.account {
    flex: 12;
    // border-radius: 32px;
    border: 1px solid var(--border-main);
    background: var(--background-1);
    @include flex(center, start);
    flex-direction: column;

    .account_title {
        border-bottom: 1px solid var(--border-main);
        padding: 2.4vh;
        width: calc(100% - 4.8vh);
        @include flex(center, space-between);
        gap: 0.8vh;
        color: var(--nav-button-font-active);
        font-size: 1.6vh;
        font-weight: 500;
        line-height: 1.6vh;

        .left_side {
            @include flex(center, start);
            gap: 0.8vh;
            color: var(--nav-button-font-active);
            font-size: 1.6vh;
            font-weight: 500;
            line-height: 1.6vh;

            ellipse {
                fill: var(--nav-button-font-active);
            }
        }

        .right_side {
            @include flex(center, center);
            gap: 1.2vh;
            border-radius: 8px;
            background: var(--button-mine-default, #3B82F6);
            padding: 1.2vh 1.6vh;
            color: var(--global-dark-25);
            font-size: 1.4vh;
            font-weight: 500;
            line-height: 1.6vh;
            cursor: pointer;

            circle {
                stroke: var(--global-dark-25);
            }

            path {
                stroke: var(--global-dark-25);
            }
        }
    }

    .account_body {
        width: calc(100% - 4.8vh);
        padding: 2.4vh;
        height: 100%;
        @include flex(center, start);
        flex-direction: column;
        gap: 3.2vh;

        .table {

            table {
                background-color: var(--background-1) !important;
            }

            th {
                border-bottom: 1px solid var(--border-main, #2D2E32) !important;
            }

            td {
                background-color: var(--background-1) !important;
                border-radius: 0 !important;
                border-bottom: 1px solid var(--border-main, #2D2E32) !important;

                span {
                    border-radius: 4px !important;
                }
            }

        }

        .table_header {
            width: 100%;
            @include flex(center, space-between);

            .left_side {
                padding: 1.6vh;
                @include flex(center, start);
                gap: 0.8vh;
                border-radius: 8px;
                border: 1px solid var(--border-main, #26272B);
                background: var(--input-bg, #131316);

                input {
                    border: none;
                    outline: none;
                    background: none;
                    font-family: $font;
                    width: 23vh;
                    color: var(--font-mine);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
            }

            .right_side {
                @include flex(center, end);
                gap: 0.8vh;

                .select {
                    width: 19.5vh;

                    .custom_icon {
                        padding: 0.8vh;
                        // border-radius: 8px;
                        // background-color: var(--input-dropdown-button);
                        border-left: 1px solid var(--background-2, #242529);
                        @include flex(center, center);

                        path {
                            stroke: var(--font-mine);
                        }
                    }

                    .custom_option {
                        font-size: 1.4vh;
                        line-height: 1.6vh;
                        padding: 0.8vh 0.4vh 0.8vh 0.8vh;
                        border-radius: 8px;
                        position: relative;

                        .option {
                            display: inline-flex;
                            align-items: center;
                            justify-content: start;
                            width: auto;
                            padding: 0.6vh 0.8vh;
                            border-radius: 8px;
                            color: var(--font-mine);
                        }
                    }

                    .custom_option_selected {
                        font-size: 1.4vh;
                        line-height: 1.6vh;
                        padding: 0.8vh 0.4vh 0.8vh 0.8vh;
                        border-radius: 8px;
                        position: relative;
                        background-color: var(--input-bg);

                        .option {
                            display: inline-flex;
                            align-items: center;
                            justify-content: start;
                            width: auto;
                            padding: 0.6vh 0.8vh;
                            border-radius: 8px;
                            color: var(--font-mine);
                        }
                    }

                    .custom_option:hover {
                        background-color: var(--input-bg);
                        color: var(--font-mine);
                    }
                }
            }

            .mobile_add {
                display: none;
            }
        }

        .table {
            width: 100%;

            // background-color: var(--background-1) !important;
            .header_cell {
                display: flex;
                align-items: center;
                gap: 0.4vh;

                span {
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 2.4vh;
                    letter-spacing: 0.24px;
                }

                .header_name {
                    display: block !important;
                }

                .sort_icon {
                    @include flex(center, start);
                    flex-direction: column;
                    gap: 0.2vh;
                    padding: 0.3vh 0.5vh;

                    path {
                        stroke: var(--font-sub-font);
                    }
                }
            }
        }

        .mobile_transactions {
            display: none;
        }
    }
}


@media (max-width: 600px) {
    .account {
        background: none;
        border: none;
        align-items: start;
        margin: 16px;

        .account_title {
            border: none;
            padding: 0;
            width: 100%;
            margin-left: 20px;

            .left_side {
                font-size: 16px;
                line-height: 16px;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            .right_side {
                display: none;
            }
        }

        .account_body {
            width: 100%;
            margin-top: 24px;
            padding: 0;

            .table_header {
                width: 90%;

                .left_side {
                    width: 60%;
                }

                .right_side {
                    display: none;
                }

                .filter {
                    @include flex(center, center);
                    padding: 12px;
                    border-radius: 8px;
                    border: 1px solid rgba(255, 255, 255, 0.08);
                    background: var(--button-alternative-default, #303034);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                }

                .mobile_add {
                    @include flex(center, center);
                    border-radius: 8px;
                    padding: 13px;
                    background: var(--button-mine-default, #3B82F6);

                    svg {
                        width: 16px;
                        height: 16px;
                    }

                    circle {
                        stroke: var(--global-dark-25);
                    }

                    path {
                        stroke: var(--global-dark-25);
                    }
                }
            }
        }

        .table {
            display: none;
        }

        .mobile_transactions {
            width: 90%;
            display: flex !important;
            align-items: start;
            justify-content: start;
            flex-direction: column;

            .label {
                @include flex(center, start);
                gap: 8px;
                color: var(--nav-button-font-active);
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;

                path {
                    fill: var(--font-mine);
                }
            }

            .transactions_list {
                width: 100%;
                @include flex(center, start);
                flex-direction: column;
                gap: 8px;

                .transaction {
                    width: calc(100% - 48px);
                    padding: 24px;
                    @include flex(center, start);
                    flex-direction: column;
                    border-radius: 8px;
                    background: var(--background-1, #18181B);
                    gap: 16px;
                    border: 1px solid var(--border-main, #2D2E32);

                    .type_status {
                        width: 100%;
                        @include flex(center, space-between);

                        .type {
                            @include flex(center, center);
                            gap: 8px;
                            padding: 4px 8px;
                            border-radius: 8px;
                            background: rgba(0, 180, 160, 0.12);
                            color: #00B4A0;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: -0.26px;

                            &.with {
                                color: #E9770E;
                                background: rgba(233, 119, 14, 0.08);
                            }
                        }

                        .status {
                            padding: 4px 8px;
                            border-radius: 8px;
                            background: rgba(82, 165, 229, 0.08);
                            color: var(--Other-Brand-main, #52A5E5);
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: -0.26px;
                        }
                    }

                    .account_amount {
                        width: 100%;
                        @include flex(center, space-between);

                        .account {
                            @include flex(start, start);
                            flex-direction: column;
                            gap: 4px;

                            label {
                                color: var(--font-sub-font, #51525C);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                            }

                            a {
                                color: var(--font-mine, #FCFCFC);
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 16px;
                                margin: 0;
                                text-decoration: none
                            }
                        }

                        .amount {
                            @include flex(end, start);
                            flex-direction: column;
                            gap: 4px;

                            label {
                                color: var(--font-sub-font, #51525C);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                            }

                            p {
                                @include flex(center, center);
                                gap: 4px;
                                color: var(--Other-Green, #00B4A0);
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 16px;
                                margin: 0;

                                &.with {
                                    color: #E9770E;
                                }
                            }
                        }
                    }

                    .id_date {
                        width: 100%;
                        @include flex(center, space-between);

                        .id,
                        .date {
                            color: var(--font-mine, #FCFCFC);
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16px;
                            margin: 0;
                        }
                    }
                }

            }
        }
    }
}

.filter_drawer {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    background-color: var(--background-2);
    justify-content: space-between;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid var(--border-main, #2D2E32);

        .left_side {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;

            div {
                display: flex;
                padding: 8px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                background: var(--border-main, #2D2E32);
            }

            span {
                color: var(--font-mine, #FCFCFC);
                font-family: 'Inter';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.28px;
            }
        }

        .right_side {
            display: flex;
            padding: 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.08);
            background: var(--button-alternative-default, #303034);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
        }
    }

    .body {
        padding: 16px;
        gap: 16px;

        .select {
            margin-bottom: 16px;

            .custom_icon {
                padding: 0.8vh;
                // border-radius: 8px;
                // background-color: var(--input-dropdown-button);
                border-left: 1px solid var(--background-2, #242529);
                @include flex(center, center);

                path {
                    stroke: var(--font-mine);
                }
            }

            .custom_option {
                font-size: 1.4vh;
                line-height: 1.6vh;
                padding: 0.8vh 0.4vh 0.8vh 0.8vh;
                border-radius: 8px;
                position: relative;

                .option {
                    display: inline-flex;
                    align-items: center;
                    justify-content: start;
                    width: auto;
                    padding: 0.6vh 0.8vh;
                    border-radius: 8px;
                    color: var(--font-mine);
                }
            }

            .custom_option_selected {
                font-size: 1.4vh;
                line-height: 1.6vh;
                padding: 0.8vh 0.4vh 0.8vh 0.8vh;
                border-radius: 8px;
                position: relative;
                background-color: var(--input-bg);

                .option {
                    display: inline-flex;
                    align-items: center;
                    justify-content: start;
                    width: auto;
                    padding: 0.6vh 0.8vh;
                    border-radius: 8px;
                    color: var(--font-mine);
                }
            }

            .custom_option:hover {
                background-color: var(--input-bg);
                color: var(--font-mine);
            }
        }
    }

    .footer {
        padding: 16px;
        gap: 16px;
        display: flex;
        align-self: center;
        justify-content: center;
        border-top: 1px solid var(--border-main, #2D2E32);
        width: 90%;

        button {
            display: flex;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.24);
            background: var(--brand-mine, #3B82F6);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
            color: var(--text-mine, #FFF);
            text-align: center;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }
    }
}