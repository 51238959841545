* {
  box-sizing: border-box;
  scrollbar-color: #555 #161c24;
  scrollbar-width: thin;
  text-decoration: none;
}

html {
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

button, a {
  cursor: pointer;
}

#root {
  width: 100vw;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiPaper-root{
  box-shadow: none !important;
  background: none !important;
}

.MuiPopper-root{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
}
body {
  margin: 0;
  font-family: "Inter Tight", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  scrollbar-width: thin; /* "auto" или "thin" */
  scrollbar-color: #555 #161C24; /* Цвет ползунка и дорожки */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.swiper-container{
  height: 500px;
}
.Custom_Confirm{
  font-family: 'Inter Tight', sans-serif;
  font-size: 15px;
  color: rgb(17, 141, 87);
  background-color: rgba(34, 197, 94, 0.16);
  border: none;
  margin-right: 10px;
  padding: 10px;
  border-radius: 6px;
  font-weight: 700;
  cursor: pointer;
}
.Mui-expanded{
  margin: 0 !important;
}
.waterMark {
  position: absolute;
  bottom: 32px;
  left: 10px;
  display: flex;
  font-size: 3.5vh;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  height: 10vh;
  width: 15vw;
  color: #54637A;
  justify-content: center;
  padding-right: 0.2vw;
  align-items: center;
  gap: 0.5vw;
  border-radius: 2%;
  background-color: #161C24;
  cursor: default;
}
@media (max-width: 1554px){
  .waterMark {
      
  left: 10px;
  
  width: 29vw;
  }
}
@media (max-width: 1211px){
  .waterMark {
      
  left: 10px;
  
  width: 33vw;
  }
}

@media (max-width: 1006px){
  .waterMark {
      
  left: 10px;
  
  width: 37vw;
  }
}
@media (max-width: 860px){
  .waterMark {
      
  left: 10px;
  
  width: 41vw;
  }
}

@media (max-width: 700px){
  .waterMark {
      
  left: 10px;
  
  width: 45vw;
  }
}  

@media (max-width: 653px){
  .waterMark {
      
  left: 10px;
  
  width: 50vw;
  }
} 

@media (max-width: 565px){
  .waterMark {
      
  left: 10px;
  
  width: 55vw;
  }
}

@media (max-width: 476px){
  .waterMark {
      
  left: 10px;
  
  width: 65vw;
  }
}

@media (max-width: 390px){
  .waterMark {
      
  left: 10px;
  
  width: 70vw;
  }
}