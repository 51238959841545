@import './theme.scss';
@import './vars.scss';

@mixin for-mobile {
    @media screen and (max-width: 600px) {
      @content;
    }
  }

  @mixin for-middle {
    @media screen and (min-width: 600.1px) and (max-width: 1200px) {
      @content;
    }
  }

.layout{
    // padding: 1.6vh;
    width: 100vw;
    @include flex(stretch,stretch);
    flex-direction: column;
    min-height: 100vh;
    // gap: 1.6vh;
    background: var(--background-1, #131316);
    overflow: hidden;

    @include for-mobile {
    // padding: 16px;
    max-width: 100%;
    }
    .page{
        flex: 12;
        @include flex(stretch,stretch);
        // gap: 1.6vh;
    }
}

