@import '../../styles/vars.scss';

@mixin for-mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin for-middle {
  @media screen and (min-width: 600.1px) and (max-width: 1200px) {
    @content;
  }
}

@mixin for-middle1 {
  @media screen and (min-width: 600.1px) and (max-width: 1000px) {
    @content;
  }
}

.page {
  overflow-x: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #242529;

  @include for-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.trade_info {
  margin: 96px 32px 120px 32px;
  background: url('../../imgs/Frame 2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
}

.header {
  width: 100%;
  @include flex(flex-start, center);
  flex-direction: column;
  position: relative;
  overflow: hidden;

  h1 {
    color: #FFF;
    font-family: 'Manrope';
    font-size: 72px;
    font-style: normal;
    font-weight: 500;
    line-height: 84px;
    margin: 0;
    margin-bottom: 24px;
    z-index: 2;
    padding: 80px 80px 0;
  
  @include for-mobile {
    padding: 48px 16px 0;
    color: var(--Global-Dark-25, #FCFCFC);
    text-align: start;
    font-size: 40px;
    line-height: normal;
  }
  
  @include for-middle {
      padding: 48px 5% 0;
      font-size: 40px;
      line-height: normal;
    }
  }

  p {
    color: #FFF;
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    text-align: start;
    z-index: 2;
    padding: 0 80px 0;

    @include for-mobile {
      padding: 0 16px;
    }

    @include for-middle {
      padding: 0 5%;
      text-align: start;
    }
  }

  p:last-child {
    padding: 0 80px 48px;

    @include for-mobile {
      padding: 0 16px 48px;
    }

    @include for-middle {
      padding: 0 5% 48px;
      text-align: start;
    }
  }

  .backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(100%);
    z-index: 1;
    transform: scaleX(-1);
  }
  

  .gradient_top {
    position: absolute;
    top: 0;
    width: 100%;
    transform: rotate(180deg);
    z-index: 2;
  }

  .gradient_bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }
}


.info_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include for-mobile {
    margin-left: 16px;
    margin-right: 16px;
    max-width: calc(100% - 32px);
    justify-content: start;
  }
}

.info {
  color: var(--Global-Dark-25, #FCFCFC);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  padding: 80px;
  gap: 24px;

  @include for-mobile {
    width: 100%;
    padding: 0;
  }

  @include for-middle {
    width: 100%;
    padding: 48px 5%;
  }

  h1 {
    color: #FFF;
font-family: 'Manrope';
font-size: 64px;
font-style: normal;
font-weight: 500;
line-height: 64px;
    margin: 0;
    text-align: start;

    @include for-mobile {
      color: #FFF;
      text-align: start;
      font-size: 32px;
      font-weight: 500;
      overflow-wrap: break-word;
      width: 100%;
      line-height: normal;
    }

    @include for-middle {
      width: 100%;
    }
  }

  p {
    color: #FFF;
font-family: 'Manrope';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
    margin: 0;
    width: 800px;
    text-align: start;

    @include for-mobile {
      color: #FFF;
      text-align: start;
      font-size: 14px;
      font-weight: 700;
      width: 100%;
    }
    
    @include for-middle {
      width: 100%;
    }
  }

  span {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    width: 800px;
    color: #ffffff54;
    text-align: start;

    @include for-mobile {
      width: 100%;
      color: var(--Font-Sub-Font, #51525C);
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
    
    @include for-middle {
      width: 100%;
    }
  }
}

.blocks {
  width: 100%;
  display: flex;
  margin-top: 40px;
  align-items: stretch;
  justify-content: space-between;
  flex: 1 0 0;
  margin-bottom: 80px;
  
  @include for-mobile {
    flex-direction: column;
    margin-bottom: 0;
  }
  
  @include for-middle1 {
    flex-direction: column;
  }
  
  .block {
    flex: 1;
    color: #FCFCFC;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #51525C;
    border-top: 1px solid #51525C;
    border-bottom: 1px solid #51525C;
    
    @include for-mobile {
      border-right: none;
    }
    
      @include for-middle1 {
        padding: 15%;
      }
  }
}

.block:last-child {
  justify-content: space-between;
}

.button {
  display: flex;
height: 40px;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 8px;
border: 1px solid rgba(255, 255, 255, 0.24);
background: #3B82F6;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
color: #FFF;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
margin-top: 74px;
}

.button:hover {
  border-radius: 8px;
border: 1px solid rgba(255, 255, 255, 0.24);
background: #458CFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
color: #FFF;
}

.blocks__background_orange {
  display: flex;
  align-self: stretch;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 54px;
  background: rgba(249, 115, 22, 0.08);
  border-radius: 4px;
}

.blocks__background_green {
  display: flex;
  align-self: stretch;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 54px;
  background: rgba(16, 185, 129, 0.08);
  border-radius: 4px;
}

.blocks__background_blue {
  display: flex;
  align-self: stretch;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 54px;
  background: rgba(59, 130, 246, 0.08);
  border-radius: 4px;
}

.blocks__orange_info {
  color: #E9770E;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 8px;
}

.blocks__green_info {
  color: #00B4A0;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 8px;
}

.blocks__blue_info {
  color: #52A5E5;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 8px;
}

.title {
  color: #FFF;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.list {
  color: var(--Font-Sub-Font, #51525C);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 0px;
  padding-left: 0px;
  align-self: flex-start;
  width: 100%;
  text-align: start;

  li {
    color: var(--Font-Mine, #FCFCFC);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    list-style: none;
    margin-bottom: 12px;
    display: flex;
    border-radius: 4px;
border: 1px solid #2D2E32;
padding: 12px;
width: 100%;
  }

  li:first-child {
    margin-top: 16px;
  }
}

.custom_checkbox {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  user-select: none;
}

.custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom_checkbox .checkmark {
  position: absolute;
  left: 0;
  top: 0;
  height: 16px;
  width: 16px;
  background-color: var(--Background-2, #18181B);
  border-radius: 50%;
  border: 1px solid #cccccc38;
  transition: background-color 0.3s ease;
}

.custom_checkbox:hover .checkmark {
  background-color: var(--Background-2, #18181B);
}

.custom_checkbox input:checked ~ .checkmark {
  background-color: var(--Background-2, #18181B);
  border-color: #3B82F6;
}

.custom_checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 1.5px;
  left: 1.5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #3B82F6;
}

.custom_checkbox input:checked ~ .checkmark:after {
  display: block;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @include for-mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.last_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}