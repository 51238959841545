@import '../../styles/theme.scss';
@import '../../styles/vars.scss';

@mixin for-middle {
    @media screen and (min-width: 1050.1px) and (max-width: 1250px) {
        @content;
    }
}

@mixin for-middle2 {
    @media screen and (min-width: 600.1px) and (max-width: 1050px) {
        @content;
    }
}

@mixin for-middle3 {
    @media screen and (min-width: 600.1px) and (max-width: 3500px) {
        @content;
    }
}

@mixin for-mobile {
    @media screen and (max-width: 830px) {
        @content;
    }
}

.home{
    flex: 12;
    // border-radius: 32px;
    border: 1px solid var(--border-main);
    background: var(--background-1, #242529);;
    @include flex(center,start);
    flex-direction: column;
    .home_title{
        border-bottom: 1px solid var(--border-main);
        padding: 2.4vh;
        width: calc(100% - 4.8vh);
        @include flex(center,start);
        gap: 0.8vh;
        color: var(--nav-button-font-active);
        font-size: 1.6vh;
        font-weight: 500;
        line-height: 1.6vh;
        path{
            fill: var(--nav-button-font-active);
        }
    }
    .home_body{
        width: calc(100% - 2.4vh);
        padding: 2.4vh;
        @include flex(center,start);
        flex-direction: column;
        .info_accounts{
            width: 100%;
            @include flex(stretch,stretch);
            gap: 1.6vh;
            margin-bottom: 3.2vh;
            .info{
                flex: 1;
                padding: 2.4vh;
                border-radius: 8px;
                border: 1px solid var(--border-main);
                @include flex(center,start);
                flex-direction: column;
                .info_title{
                    width: 100%;
                    @include flex(start,space-between);
                    padding-bottom: 1.6vh;
                    border-bottom: 1px solid var(--border-main);
                    .name_email{
                        @include flex(start,start);
                        flex-direction: column;
                        gap: 0.8vh;
                        p{
                            margin: 0;
                            color: var(--font-mine);
                            font-size: 1.6vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                        }
                        span{
                            color: var(--font-sub-font);
                            font-size: 1.2vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                        }
                    }
                    .id{
                        color: var(--font-mine);
                        font-size: 1.6vh;
                        font-weight: 500;
                        line-height: 1.6vh;
                        span{
                            color: var(--brand-mine, #3B82F6);
                        }
                    }
                }
                .info_body{
                    
                    position: relative;
                    width: 100%;
                    height: 28.8vh;
                    .balance_block {
                        margin-top: 1.6vh;
                        @include flex(start, space-between);

                        .balance {
                            @include flex(start, start);
                            flex-direction: column;
                            gap: 1.2vh;

                            span {
                                color: var(--font-sub-font);
                                font-size: 1.2vh;
                                font-weight: 500;
                                line-height: 1.6vh;
                            }

                            h1 {
                                margin: 0;
                                color: var(--font-mine);
                                font-size: 4vh;
                                font-weight: 600;
                                line-height: 4.8vh;

                                @include for-mobile {
                                    font-size: 3vh;
                                }
                            }
                        }
                    }

                    .free_balance_block {
                        margin-top: 3.2vh;
                        @include flex(start, space-between);

                        .balance {
                            @include flex(start, start);
                            flex-direction: column;
                            gap: 1.2vh;

                            span {
                                color: var(--font-sub-font);
                                font-size: 1.2vh;
                                font-weight: 500;
                                line-height: 1.6vh;
                            }

                            h1 {
                                margin: 0;
                                color: var(--font-mine);
                                font-size: 2vh;
                                font-weight: 600;
                                line-height: 2.4vh;
                            }
                        }
                    }
                    .currency_block {
                        margin-top: 3.2vh;
                        @include flex(start,space-between);

                        @include for-middle3 {
                            margin-top: 0;
                        }

                        .free_balance{
                            margin-top: 2vh;
                            @include flex(start,start);
                            flex-direction: column;
                            gap: 1.2vh;

                            span {
                                color: var(--font-sub-font);
                                font-size: 1.2vh;
                                font-weight: 500;
                                line-height: 1.6vh;
                            }
                            h1{
                                @include flex(center,start);
                                gap: 0.8vh;
                                margin: 0;
                                color: var(--font-mine);
                                font-size: 2vh;
                                font-weight: 600;
                                line-height: 2.4vh;
                            }
                        }
                    }
                    .bg_chart{
                        position: absolute;
                        top: 5vh;
                        right: 0;
                        width: 65%;
                        overflow: hidden;
                    }
                    .bg_circle{
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
                .info_buttons{
                    margin-top: 6vh;
                    width: 100%;
                    @include flex(stretch,stretch);
                    gap: 0.8vh;
                    button , a{
                        cursor: pointer;
                        flex: 1;
                        @include flex(center,center);
                        gap: 1.2vh;
                        text-decoration: none;
                        color: var(--nav-button-font-active);
                        font-size: 1.4vh;
                        font-weight: 500;
                        line-height: 1.6vh;
                        padding: 1.2vh 1.6vh;
                        font-family: $font;
                        transition: all 0.15s ease-in-out;
                        border-radius: 8px;
                        border: 1px solid var(--Border-Button, rgba(255, 255, 255, 0.08));
                        background: var(--button-alternative-default, #303034);
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

                        svg {
                            path {
                                stroke: var(--font-mine);
                            }
                        }
                    }
                    a:hover,button:hover{
                        background: var(--buttons-alternative-hover);
                    }
                }
            }
            .accounts{
                flex: 2.424;
                padding: 2.4vh;
                border-radius: 8px;
                border: 1px solid var(--border-main);
                gap: 2.4vh;
                @include flex(center,start);
                flex-direction: column;
                .accounts_title{
                    width: 100%;
                    @include flex(center,space-between);
                    .left_account_title{
                        @include flex(start,start);
                        flex-direction: column;
                        gap: 0.8vh;
                        p{
                            color: var(--font-mine);
                            font-size: 1.6vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                            margin: 0;
                        }
                        span{
                            color: var(--font-sub-font);
                            font-size: 1.2vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                        }
                    }
                    .right_account_title{
                        @include flex(center,center);
                        gap: 1.2vh;
                        cursor: pointer;
                        color: var(--font-mine);
                        font-size: 1.4vh;
                        font-weight: 500;
                        line-height: 1.6vh;
                        padding: 1.2vh;
                        border-radius: 8px;
                        border: 1px solid var(--border-button, rgba(255, 255, 255, 0.08));
                        background: var(--button-alternative-default, #303034);
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                        path{
                            stroke: var(--font-mine)
                        }
                        circle{
                            stroke: var(--font-mine)
                        }
                    }
                }
                .accounts_body{
                    @include flex(start,start);
                    flex-wrap: wrap;
                    gap: 1.6vh;
                    max-height: calc(2 * 18vh + 1.6vh);
                    overflow-y: auto; 
                    width: 100%;
                    .account_block {
                        padding: 1.6vh;
                        width: calc((100% - 3.2vh) / 3); 
                        display: flex;
                        align-items: center;
                        justify-content: start;
                        flex-direction: column;
                        border-radius: 8px;
                        background: var(--account-bg-real-svg);
                        background-repeat: no-repeat;
                        gap: 2.4vh;
                        background-size: cover !important;
                        
                        &.demo_bg{
                            background: var(--account-bg-demo-svg);
                            background-repeat: no-repeat;
                            background-size: 100%;

                        }
                        .top_block{
                            width: 100%;
                            @include flex(center,start);
                            flex-direction: column;
                            gap: 1.6vh;
                            .currency{
                                width: 100%;
                                @include flex(center,space-between);
                                .currency_left{
                                    @include flex(center,start);
                                    gap: 0.8vh;
                                    color: var(--font-mine);
                                    font-size: 1.6vh;
                                    font-weight: 500;
                                    line-height: 1.6vh;
                                }
                                .currency_right{
                                    @include flex(center,end);
                                }
                            }
                            .balance{
                                width: 100%;
                                p{
                                    margin: 0;
                                    color: var(--global-dark-400);
                                    font-size: 1.2vh;
                                    font-weight: 500;
                                    line-height: 1.6vh;
                                }
                                h1{
                                    margin: 0;
                                    margin-top: 0.8vh;
                                    color: var(--font-mine);
                                    font-size: 24px;
                                    font-weight: 700;
                                    line-height: 24px;
                                }
                            }
                        }
                        .bottom_block{
                            width: 100%;
                            @include flex(center,space-between);
                            .account_name{
                                color: var(--font-mine);
                                font-size: 1.4vh;
                                font-weight: 500;
                                line-height: 1.6vh;
                            }
                            .type{
                                font-size: 1.2vh;
                                font-weight: 500;
                                line-height: 1.6vh;
                                letter-spacing: 0.24px;
                                color: var(--other-brand-main, #52A5E5);
                                border-radius: 4px;
                                background: rgba(82, 165, 229, 0.08);
                                padding: 0.4vh 1.2vh;
                                &.demo{
                                    color: var(--other-orange, #E9770E);
                                    border-radius: 4px;
                                    background: rgba(233, 119, 14, 0.08);
                                }
                            }
                        }
                    }
                }
            }
        }
        .transactions{
            width: 100%;
            .header_cell{
                display: flex;
                align-items: center;
                gap: 0.4vh;
                span{
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 2.4vh;
                    letter-spacing: 0.24px;
                }
                .header_name{
                    display: block !important;
                }
                .sort_icon{
                    @include flex(center,start);
                    flex-direction: column;
                    gap: 0.2vh;
                    padding: 0.3vh 0.5vh;
                    path{
                        stroke: var(--font-sub-font);
                    }
                }
            }
            .transaction_header{
                margin-bottom: 1.6vh;
                @include flex(center,space-between);
                h3{
                    margin: 0;
                    color: var(--font-mine);
                    font-size: 1.6vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
            }
        }
        .mobile_transactions{
            display: none;
        }
    }
}

@media (max-width: 1200px) {
    .home{
        background: none;
        border: none;
        align-items: start;
        .home_body{
            width: 100%;
            margin-top: 1.2vh;
            padding: 0;
            align-items: start;
        }
        .home_title{
            width: 100%;
            border: none; 
            padding: 16px;
            border-bottom: 1px solid var(--border-main, #2D2E32);
        }
        .info_accounts{
            width: 100%;
            flex-wrap: wrap;
            .info{
                width: 100%;
                margin: 0 16px;
            }
            .accounts{
                // border: none !important;
                background: var(--background-1, #18181B);
                border: 1px solid var(--border-main, #2D2E32);
                margin: 0 16px;
                .accounts_body{
                    width: 100%;
                    flex-direction: column;
                    flex-wrap: nowrap !important;
                    .account_block {
                        width: 90% !important;
                        background-repeat: no-repeat;
                        background-size: cover !important;

                        .top_block{
                            .balance{
                                p{
                                    font-size: 12px !important;
                                }
                            }
                        }
                        .account_name{
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
        .transactions{
            display: none;
        }
        .mobile_transactions{
            width: 100%;
            display: flex !important;
            align-items: start;
            justify-content: start;
            flex-direction: column;
            .label{
                @include flex(center,start);
                gap: 8px;
                color: var(--nav-button-font-active);
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
                margin: 0 16px;
                path{
                    fill: var(--font-mine);
                }
            }
            .transactions_list{
                margin: 3.2vh 16px;
                width: 100%;
                @include flex(start,start);
                flex-direction: column;
                // gap: 8px;
                .transaction{
                    width: calc(100% - 80px);
                    padding: 24px;
                    @include flex(center,start);
                    flex-direction: column;
                    border-radius: 8px;
                    background: var(--background-1, #18181B);
                    border: 1px solid var(--border-main, #2D2E32);
                    gap: 16px;
                    .type_status{
                        width: 100%;
                        @include flex(center,space-between);
                        .type {
                            @include flex(center,center);
                            gap: 8px;
                            padding: 4px 8px;
                            border-radius: 4px;
                            background: rgba(0, 180, 160, 0.12);
                            color: #00B4A0;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px; 
                            letter-spacing: -0.26px;
                            &.with{
                                color: #E9770E;
                                background: rgba(233, 119, 14, 0.08);
                            }
                        }
                        .status{
                            padding: 4px 8px;
                            border-radius: 4px;
                            background: rgba(82, 165, 229, 0.08);
                            color: var(--Other-Brand-main, #52A5E5);
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px; 
                            letter-spacing: -0.26px;
                        }
                    }
                    .account_amount{
                        width: 100%;
                        @include flex(center,space-between);
                        .account{
                            @include flex(start,start);
                            flex-direction: column;
                            gap: 4px;
                            label{
                                color: var(--font-sub-font, #51525C);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                            }
                            p{
                                color: var(--font-mine, #FCFCFC);
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 16px;
                                margin: 0;
                            }
                        }
                        .amount{
                            @include flex(end,start);
                            flex-direction: column;
                            gap: 4px;
                            label{
                                color: var(--font-sub-font, #51525C);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                            }
                            p{
                                @include flex(center,center);
                                gap: 4px;
                                color: var(--Other-Green, #00B4A0);
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 16px;
                                margin: 0;
                                &.with{
                                    color: #E9770E;
                                }
                            }
                        }
                    }
                    .id_date{
                        width: 100%;
                        @include flex(center,space-between);
                        .id , .date{
                            color: var(--font-mine, #FCFCFC);
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16px;
                            margin: 0;
                        }
                    }
                }

            }
        }
    }
}