@mixin for-mobile {
    @media screen and (max-width: 1100px) {
        @content;
    }
}

@mixin for-middle {
    @media screen and (min-width: 600.1px) and (max-width: 1200px) {
        @content;
    }
}

.header {
    background: var(--Background-2, #242529);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 80px;
    width: 100%;
    z-index: 10;

    @include for-mobile {
        padding: 26px 5%;
    }

    .left_side {
        display: flex;
        align-items: center;
        gap: 48px;

        .logo_container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 76px;

            span {
                font-size: 16px;
                color: #FFF;
            }
        }

        .link {
            color: var(--Text-Mine, #FFF);
            font-family: 'Manrope';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            text-decoration: none;
            cursor: pointer;
            
            img {
                transform: rotate(90deg);
            }
        }
    }

    .right_side {
        display: flex;
        align-items: center;
        gap: 48px;

        .language_button {
            color: var(--Text-Mine, #FFF);
            font-family: 'Manrope';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        .login_button {
            color: var(--Text-Mine, #FFF);
            font-family: 'Manrope';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            text-decoration: none;
        }

        .register_button {
            color: var(--Text-Mine, #FFF);
            font-family: 'Manrope';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            text-decoration: none;
        }

        @include for-mobile {
            width: 40px;
            height: 40px;
            padding: 12px;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.08);
            background: #303034;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
        }
    }

}

.drawer {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 56px;
    border-bottom: 1px solid var(--Border-1, #2D2E32);
    background: var(--Background-2, #242529);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

    @include for-mobile {
        padding: 32px;
        flex-direction: column;
    }

    .block {
        flex: 1;

        ul {
            color: var(--Text-Sub, #51525C);
            font-family: 'Manrope';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            list-style-type: none;
            padding-left: 0;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .li {
                color: var(--Text-Mine, #FFF);
                font-family: 'Manrope';
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                text-decoration: none;
            }
        }

        .contacts {
            padding-left: 32px;
            border-left: 1px solid #51525C;

            li {
                font-size: 14px;
            }
        }
    }
}

.menu_drawer {
    border-bottom: 1px solid #2D2E32;
    background: #242529;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 32px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 200px rgba(0, 0, 0, 0.4);


    .title {
        color: #51525C;
        font-family: 'Manrope';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 24px;
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 32px;

        .list_item {
            display: flex;
            justify-content: space-between;

            div {
                display: flex;
                align-items: center;
                gap: 15px;

                span {
                    color: #51525C;
                    font-family: 'Manrope';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                p {
                    margin: 0;
                    color: #FFF;
                    font-family: 'Manrope';
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }

    .buttons {
        display: flex;
        gap: 8px;

        a {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: 1px solid rgba(255, 255, 255, 0.24);
            background: #3B82F6;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
            color: #FFF;
            font-family: 'Manrope';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            padding: 16px;
        }

        a:first-child {
            border-radius: 4px;
            border: 1px solid rgba(255, 255, 255, 0.08);
            background: #303034;
        }
    }
}

.overlay {
    position: fixed;
    top: 68px;
    left: 0;
    width: 100%;
    height: calc(100vh - 68px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;

    @include for-mobile {
        top: 92px;
        height: calc(100vh - 92px);
    }
}