@import './vars.scss';
@import './theme.scss';

.paper {
    padding: 0;
}

.account_drawer {
    width: 100vw;
    background-color: var(--background-2);
    position: relative;
    @include flex(center, space-between);
    flex-direction: column;
    height: 100vh;
    .top_drawer{
        width: 100%;
        @include flex(center,start);
        flex-direction: column;
        .drawer_header {
            width: calc(100% - 32px);
            border-bottom: 1px solid var(--border-main);
            padding: 16px;
            color: var(--font-mine);
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.28px;
            @include flex(center, space-between);
            .logo{
                @include flex(center, start);
                gap: 12px;
                color: var(--font-mine, #FCFCFC);
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                img{
                    width: 35px;
                    height: 36px;
                }
            }            
            .close_icon{
                @include flex(center, center);
                padding: 12px;
                border-radius: 8px;
                background: var(--buttons-alternative-default);
                path{
                    stroke: var(--font-mine);
                }
            }
        }
        .drawer_body {
            width: calc(100% - 32px);
            padding: 16px;
            @include flex(center, start);
            flex-direction: column;
            gap: 16px;
            .tradingTerm{
                width: calc(100% - 2.4vh);
                @include flex(center,center);
                gap: 12px;
                border-radius: 8px;
                background: var(--brand-mine, #3B82F6);;
                padding: 1.6vh 1.2vh;   
                color: var(--global-dark-25);
                font-size: 1.4vh;
                font-weight: 500;
                line-height: 1.6vh; 
                cursor: pointer;
                text-decoration: none;
                transition: all 0.15s ease-in-out;
            }
            
            .links{
                margin-top: 2.4vh;
                width: 100%;
                @include flex(center,start);
                flex-direction: column;
                gap: 0.8vh;

                label{
                    margin-bottom: 0.8vh;
                    width: 100%;
                    text-align: left;
                    color: var(--font-sub-font);
                    font-size: 1.4vh;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
                .button{
                    width: calc(100% - 2.4vh);
                    @include flex(center,start);
                    gap: 1.2vh;
                    padding: 1.2vh;
                    color: var(--nav-button-font-default);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    text-decoration: none;
                    transition: all 0.15s ease-in-out;
                    path{
                        fill: var(--nav-button-font-default);
                    }
                    ellipse{
                        fill: var(--nav-button-font-default);
                    }
                    &.active{
                        border-radius: 8px;
                        background: var(--nav-button-active);
                        color: var(--nav-button-font-active);
                        path{
                            fill: var(--nav-button-font-active);
                        }
                        ellipse{
                            fill: var(--nav-button-font-default);
                        }
                    }
                }
                .button:hover{
                    border-radius: 8px;
                    background: var(--nav-button-hover);
                }
            }
            .settings{
                margin-top: 12px;
                width: 100%;
                @include flex(center,start);
                gap: 12px;
            .theme{
                @include flex(center,center);
                cursor: pointer;
                .moon{
                    padding: 1vh;
                    @include flex(center,center);
                    path{
                        fill: var(--font-sub-font);
                    }
                    &.active{
                        path{
                            fill:  var(--font-mine);
                        }
                    }
                }
                .sun{
                    padding: 1vh;
                    @include flex(center,center);
                    path{
                        fill: var(--font-sub-font);
                    }
                    &.active{
                        path{
                            fill:  var(--font-mine);
                        }
                    }
                }
            }
            
        .buttons {
            @include flex(center, end);
            position: relative;
            .language_dropdown {
                display: flex;
                flex-direction: column;
                background: var(--button-alternative-default, #303034);
                border: 1px solid rgba(255, 255, 255, 0.24);
                position: absolute;
                right: 0;
                top: 37px;
                border-radius: 8px;
                width: 57px;
                span {
                    cursor: pointer;
                    gap: 1.2vh;
                    transition: all 0.15s ease-in-out;
                    color: var(--nav-button-font-active) !important;
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    padding: 12px;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                }
            }

            .theme {
                @include flex(center, center);
                cursor: pointer;

                .button {
                    border-radius: 8px;
                    border: 1px solid var(--border-button, rgba(255, 255, 255, 0.08));
                    background: var(--button-alternative-default, #303034);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                    display: flex;
                    padding: 8px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;

                    svg {
                        path {
                            stroke: var(--font-mine);
                        }
                    }
                }

                // .moon{
                //     padding: 1vh;
                //     @include flex(center,center);
                //     path{
                //         fill: var(--font-sub-font);
                //     }
                //     &.active{
                //         path{
                //             fill:  var(--font-mine);
                //         }
                //     }
                // }
                // .sun{
                //     padding: 1vh;
                //     @include flex(center,center);
                //     path{
                //         fill: var(--font-sub-font);
                //     }
                //     &.active{
                //         path{
                //             fill:  var(--font-mine);
                //         }
                //     }
                // }
            }

            .lang_currency {
                @include flex(center, center);
                padding: 8px;
                gap: 1.2vh;
                border-radius: 8px;
                border: 1px solid var(--border-button, rgba(255, 255, 255, 0.08));
                background: var(--button-alternative-default, #303034);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

                .lang {
                    @include flex(center, center);
                    cursor: pointer;

                    path {
                        stroke: var(--font-mine);
                    }
                }

                p {
                    color: var(--font-mine);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    margin: 1px;
                    margin-right: 8px;
                }

                .border {
                    @include flex(center, center);

                    path {
                        stroke: var(--border-main);
                    }
                }

                .currency {
                    @include flex(center, center);
                    cursor: pointer;
                    color: var(--nav-button-font-active);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
            }

            .logout {
                @include flex(center, start);
                cursor: pointer;
                gap: 1.2vh;
                transition: all 0.15s ease-in-out;
                color: var(--nav-button-font-active, #FCFCFC);
                font-size: 1.4vh;
                font-weight: 500;
                line-height: 1.6vh;
                border-radius: 8px;
                padding: 8px 20px;
                border: 1px solid rgba(255, 255, 255, 0.24);
                background: var(--button-mine-default, #3B82F6);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

            }

            .logout:hover {
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.24);
                background: var(--button-mine-hover, #458CFF);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
            }
        }
            .lang_currency{
                @include flex(center,center);
                padding: 1.2vh;
                gap: 1.2vh;
                border-radius: 8px;
                background: var(--buttons-alternative-default);
                .lang{
                    @include flex(center,center);
                    cursor: pointer;
                    path{
                        stroke: var(--font-mine)
                    }
                }
                .border{
                    @include flex(center,center);
                    path{
                        stroke: var(--border-main);
                    }
                }
                .currency{
                    @include flex(center,center);
                    cursor: pointer;
                    color: var(--nav-button-font-active);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
            }
            }
        }
    }
    .drawer_footer{
        width: calc(100% - 3.2vh);
        padding: 1.6vh;
        @include flex(center,center);
        gap: 0.8vh;
        border-top: 1px solid var(--border-main);
        div{
            flex: 1;
            @include flex(center,center);
            border-radius: 8px;
            background: var(--buttons-alternative-default);
            color: var(--nav-button-font-active);
            font-size: 14px;
            padding: 16px;
            font-weight: 500;
            line-height: 1.6vh;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
        }
        div:hover{
            background: var(--buttons-alternative-hover);
        }
    }
}