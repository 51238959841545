@import './vars.scss';
@import './theme.scss';

.paper {
    padding: 0;
}
.status{
    display: flex;
    padding: 0.4vh 0.3vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vh;
    border-radius: 0.4vh;
    font-family: Inter;
    font-size: 1.6vh;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6vh;
    letter-spacing: 0.24px;
    width: max-content;
}

.status_good{
    @extend .status;
    color: var(--status-good);
    background: var(--status-good-bg);
}
.status_pending{
    @extend .status;
    color: var(--status-pending);
    background: var(--status-pending-bg);
}
.status_denied{
    @extend .status;
    color: var(--status-denied);
    background: var(--status-denied-bg);
}
.account_drawer {
    width: calc(100% - 2px);
    background-color: var(--background-2);
    position: relative;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    border: 1px solid var(--border-main);
    @include flex(center, space-between);
    flex-direction: column;

    .close_icon {
        position: absolute;
        top: 0;
        left: -50px;
        padding: 1.2vh;
        @include flex(center, center);
        border-radius: var(--corner-full, 1000px);
        border: 1px solid var(--border-main);
        background: var(--background-2);
        cursor: pointer;

        path {
            stroke: var(--font-sub-font);
        }
    }
    .top_drawer{
        width: 100%;
        @include flex(center,start);
        flex-direction: column;
        .drawer_header {
            width: calc(100% - 32px);
            border-bottom: 1px solid var(--border-main);
            padding: 16px;
            color: var(--font-mine);
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.28px;
            @include flex(center, start);
            gap: 1.2vh;
            img{
                width: 24px;
                height: 24px;
            }
            .type{
                @include flex(center,center);
                padding: 4px 12px;
                border-radius: 9999px;
                background: rgba(246, 61, 104, 0.08);
                color: var(--Other-Red, #F63D68);
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.24px;
                &.buy{
                    color: var(--Other-Green, #00B4A0);
                    background: rgba(0, 180, 160, 0.08);
                }
                &.sell{
                    background: rgba(246, 61, 104, 0.08);
                    color: var(--Other-Red, #F63D68);
                }
                &.simulate{
                    background: rgba(233, 119, 14, 0.08);
                    color: #E9770E;
                }
            }
        }
        .drawer_body {
            width: calc(100% - 32px);
            padding: 16px;
            @include flex(center, start);
            flex-direction: column;
            gap: 16px;
            .values{
                width: 100%;
                @include flex(center,space-between);
                label{
                    color: var(--font-sub-font);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                }
                .value{
                    color: var(--font-mine);
                    text-align: right;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                }
                &.tool{
                    img{
                        width: 16px;
                        height: 16px;
                    }
                    .value{
                        @include flex(center,end);
                        gap: 8px;
                    }
                }
                &.tp_sl{
                    .value{
                        @include flex(center,end);
                        gap: 8px;
                        color: var(--font-sub-font);
                        .tp{
                            color: #00B4A0;
                        }
                        .sl{
                            color: #F63D68;
                        }
                    }
                }
            }
        }
    }
    .drawer_footer{
        width: calc(100% - 3.2vh);
        padding: 1.6vh;
        @include flex(center,center);
        gap: 0.8vh;
        border-top: 1px solid var(--border-main);
        div{
            flex: 1;
            @include flex(center,center);
            border-radius: 9999px;
            background: var(--buttons-alternative-default);
            color: var(--nav-button-font-active);
            font-size: 14px;
            padding: 16px;
            font-weight: 500;
            line-height: 1.6vh;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
        }
        div:hover{
            background: var(--buttons-alternative-hover);
        }
    }
}

@media (max-width: 600px) {
    .account_drawer{
        .close_icon{
            display: none;
        }
    }
}