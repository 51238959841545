@import '../../styles/vars.scss';

@mixin for-middle {
    @media screen and (min-width: 600.1px) and (max-width: 1200px) {
        @content;
    }
}

@mixin for-desktop {
    @media screen and (min-width: 900.1px) {
        @content;
    }
}

@mixin for-mobile {
    @media screen and (max-width: 600px) {
        @content;
    }
}

.affprogram_page {
    width: 100%;
    @include flex(center, start);
    flex-direction: column;
    background: #242529;


    .header {
        width: 100%;
        @include flex(center, center);
        flex-direction: column;

        h1 {
            color: var(--Global-Dark-25, #FCFCFC);
            font-size: 64px;
            font-weight: 500;
            line-height: normal;
            margin: 0;
            margin-bottom: 12px;
        }

        p {
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: normal;
            margin: 0;
            width: 45vw;
        }
    }

    .section1 {
        width: 100vw;
        @include flex(center, center);

        .section1Body {
            @include flex(center, start);
            flex-direction: column;
            gap: 86px;

            @include for-mobile {
                justify-content: center;
                align-items: center;
            }

            .top {
                width: 100%;
                @include flex(start, start);
                flex-direction: column;
                gap: 24px;
                padding: 80px;

                @include for-mobile {
                    padding: 48px 16px;
                }

                @include for-middle {
                    padding: 48px 5%;
                }


                h1 {
                    color: #FFF;
                    font-family: 'Manrope';
                    font-size: 72px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 84px;
                    margin: 0;
                    text-align: start;

                    @include for-middle {
                        font-size: 40px;
                        line-height: normal;
                    }
                }

                p {
                    color: #FFF;
                    font-family: 'Manrope';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    margin: 0;
                    text-align: start;

                    @include for-middle {
                        font-size: 18px;
                        line-height: normal;
                    }
                }
            }

            .bottom {
                width: 100%;
                @include flex(center, start);
                flex-direction: column;
                gap: 48px;
                padding: 0 80px;

                @include for-mobile {
                    padding: 0 16px;
                }

                @include for-middle {
                    padding: 0 16px;
                }

                .blocks {
                    width: 100%;
                    @include flex(start, start);
                    flex-direction: column;
                    gap: 32px;

                    .top_blocks {
                        @include flex(stretch, stretch);
                        flex-wrap: wrap;
                        gap: 32px;
                        width: 100%;

                        .block {
                            flex: 1;
                            padding: 32px;
                            @include flex(center, start);
                            flex-direction: column;
                            gap: 32px;
                            border-radius: 4px;
                            background: var(--Button-Alternative-Press, #2E2E32);

                            @include for-mobile {
                                flex: 1 1 90%;
                            }

                            p {
                                color: var(--Font-Mine, #FCFCFC);
                                font-size: 18px;
                                font-weight: 500;
                                line-height: normal;
                                margin: 0;
                                text-align: center;

                                @include for-mobile {
                                    text-align: center
                                }
                            }
                        }
                    }

                    .bottom_blocks {
                        @include flex(stretch, stretch);
                        flex-wrap: wrap;
                        gap: 32px;
                        width: 100%;

                        @include for-mobile {
                            margin-left: 0;
                        }

                        .block {
                            flex: 1;
                            padding: 32px;
                            @include flex(center, start);
                            flex-direction: column;
                            gap: 32px;
                            border-radius: 4px;
                            background: var(--Button-Alternative-Press, #2E2E32);

                            @include for-mobile {
                                min-width: 100%;
                            }

                            p {
                                color: var(--Font-Mine, #FCFCFC);
                                font-size: 18px;
                                font-weight: 500;
                                line-height: normal;
                                margin: 0;
                                text-align: center;

                                @include for-mobile {
                                    text-align: center
                                }
                            }
                        }
                    }
                }

                a {
                    display: flex;
                    width: 200px;
                    padding: 12px;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    border-radius: 8px;
                    border: 1px solid rgba(255, 255, 255, 0.24);
                    background: #3B82F6;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                    text-decoration: none;
                    color: var(--Nav_Button-Font_Active, #FCFCFC);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;

                    @include for-mobile {
                        width: 100%;
                    }
                }

                a:hover {
                    border-radius: 8px;
                    border: 1px solid rgba(255, 255, 255, 0.24);
                    background: #458CFF;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                    color: #FFF;
                }
            }
        }
    }

    .section2 {
        margin-top: 154px;
        width: 100%;
        @include flex(center, center);

        .section2Body {
            @include flex(center, start);
            flex-direction: column;
            gap: 86px;

            .title {
                @include flex(center, center);
                width: 100%;
                padding: 0 80px;

                @include for-mobile {
                    padding: 0 16px;
                }

                @include for-middle {
                    padding: 0 5%;
                }

                h1 {
                    color: var(--Neutrals-White, #FFF);
                    text-align: start;
                    font-size: 48px;
                    font-weight: 500;
                    line-height: normal;
                    margin: 0;

                    @include for-middle {
                        font-size: 40px;
                    }
                }
            }

            .blocks {
                width: 100%;
                @include flex(stretch, center);
                flex-wrap: wrap;

                .block {
                    @include flex(start, start);
                    flex-direction: column;
                    flex: 1;
                    padding: 32px;
                    gap: 32px;
                    border-right: 1px solid var(--Border-3, #51525C);
                    border-top: 1px solid var(--Border-3, #51525C);
                    border-bottom: 1px solid var(--Border-3, #51525C);
                    background: var(--Background-2, #242529);

                    @include for-mobile {
                        flex: 1 1 90%;
                    }

                    .number {
                        color: var(--Font-Mine, #FCFCFC);
                        font-size: 18px;
                        @include flex(center, center);
                        font-weight: 700;
                        line-height: normal;
                        border-radius: 1000px;
                        width: 82px;
                        height: 82px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .text {
                        h5 {
                            color: var(--Font-Mine, #FCFCFC);
                            font-size: 16px;
                            font-weight: 700;
                            line-height: normal;
                            margin: 0;
                            margin-bottom: 12px;
                        }

                        p {
                            color: var(--Global-Light-400, #9CA3AF);
                            font-size: 14px;
                            font-weight: 500;
                            line-height: normal;
                            margin: 0;
                            white-space: pre-wrap;
                        }
                    }
                }
            }
        }
    }

    .section3 {
        padding: 154px 80px;
        width: 100%;
        @include flex(center, center);

        @include for-mobile {
            padding: 48px 16px;
            flex-direction: column
        }

        @include for-middle {
            padding: 48px 5%;
            flex-direction: column
        }

        .section3Body {
            @include flex(center, start);
            flex-direction: column;
            gap: 32px;
            padding: 48px;
            border-radius: 4px;
            background: #28282D;

            .top {
                width: 100%;
                @include flex(start, center);
                gap: 48px;
                
                @include for-mobile {
                    flex-wrap: wrap;
                }

                h1 {
                    width: 50.3%;
                    color: var(--Neutrals-White, #FFF);
                    font-size: 48px;
                    font-weight: 500;
                    line-height: 56px;
                    margin: 0;

                    @include for-middle {
                        width: 100%;
                        font-size: 42px;
                        text-align: center;
                    }
                }

                p {
                    width: 49.7%;
                    color: var(--Global-Light-400, #9CA3AF);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                    margin: 0;

                    @include for-middle {
                        width: 100%;
                        text-align: center;
                    }
                }
            }

            .form {
                width: 100%;
                @include flex(center, start);
                gap: 24px;
                flex-direction: column;

                .inputs {
                    width: 100%;
                    @include flex(stretch, space-around);
                    gap: 16px;
                    flex-wrap: wrap;

                    @include for-middle {
                        justify-content: space-between
                    }

                    .input_label {
                        @include flex(start, start);
                        flex-direction: column;
                        gap: 12px;

                        @include for-desktop {
                            flex: 1;
                        }

                        label {
                            color: var(--Font-Sub-Font, #51525C);
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: 0.24px;
                        }

                        input {
                            width: calc(100% - 24px);
                            color: #FCFCFC;
                            font-family: $font;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px;
                            padding: 16px 8px 16px 16px;
                            border-radius: 8px;
                            border: 1px solid #2D2E32;
                            background: #1C1D21;
                            outline: none;

                            @include for-mobile {
                                width: 100%;
                            }

                            @include for-middle {
                                width: 100%;
                            }
                        }
                    }
                }

                .textarea_block {
                    width: 100%;
                    @include flex(start, start);
                    flex-direction: column;
                    gap: 12px;

                    label {
                        color: var(--Font-Sub-Font, #51525C);
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.24px;
                    }

                    textarea {
                        width: calc(100% - 32px);
                        padding: 16px;
                        height: 55px;
                        border-radius: 8px;
                        border: 1px solid #2D2E32;
                        background: #1C1D21;
                        outline: none;
                        resize: none;
                        color: var(--Font-Mine, #FCFCFC);
                        font-size: 14px;
                        font-family: $font;
                        font-weight: 500;
                        line-height: 16px;

                        @include for-mobile {
                            width: 100%;
                        }

                        @include for-middle {
                            width: 100%;
                        }
                    }
                }

                .link_block {
                    @include flex(center, end);
                    width: 100%;

                    a {
                        @include flex(center, center);
                        width: 200px;
                        padding: 12px;
                        color: var(--Nav_Button-Font_Active, #FCFCFC);
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        text-decoration: none;
                        border-radius: 8px;
                        border: 1px solid rgba(255, 255, 255, 0.24);
                        background: #3B82F6;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

                        @include for-mobile {
                            width: 100%;
                        }
                    }

                    a:hover {
                        border-radius: 8px;
                        border: 1px solid rgba(255, 255, 255, 0.24);
                        background: #458CFF;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                        color: #FFF;
                    }

                    @include for-middle {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .affprogram_page {
        .header {
            background-size: cover;
            background-position: bottom;

            h1 {
                text-align: center;
                font-size: 40px;
            }

            p {
                width: 90%;
                font-size: 18px;
            }
        }

        .section1 {
            .section1Body {

                .top {
                    h1 {

                        font-size: 32px;
                        line-height: normal;
                    }
                }
            }
        }

        .section2 {
            .section2Body {

                .title {
                    h1 {
                        font-size: 32px;
                    }
                }

                .blocks {
                    .block {
                        width: 100%;
                    }
                }
            }
        }

        .section3 {
            .section3Body {

                .top {
                    @include flex(center, start);

                    h1 {
                        width: 100%;
                        text-align: center;
                        font-size: 32px;
                    }

                    p {
                        width: 100%;
                        text-align: center;
                    }
                }

                .form {
                    .inputs {
                        flex-wrap: wrap;

                        .input_label {
                            width: 100%;
                        }
                    }

                    .link_block {
                        width: 100%;
                        @include flex(center, center);
                    }
                }
            }
        }
    }
}