@import '../../styles/vars.scss';
// @import '../../styles/theme.scss';

@mixin for-mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}
@mixin for-middle {
  @media screen and (min-width: 600.1px) and (max-width: 1200px) {
    @content;
  }
}

.page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #242529;

  @include for-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.header {
    margin-top: 12px;
    width: 100%;
    @include flex(start, center);
    flex-direction: column;
    padding: 80px;
    gap: 24px;
  
    @include for-mobile {
      margin-top: 0;
      padding: 48px 16px;
    }

    @include for-middle {
      margin-top: 0;
      padding: 48px 5%;
    }
  
    h1 {
      color: #FFF;
font-family: 'Manrope';
font-size: 72px;
font-style: normal;
font-weight: 500;
line-height: 84px;
      margin: 0;
  
      @include for-mobile {
          text-align: center;
          font-size: 40px;
          font-style: normal;
      }
    }
  
    p {
      color: #FFF;
font-family: 'Manrope';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
      margin: 0;
      text-align: start;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    @include for-mobile {
        width: 100%;
    }
  }

  .info__container {
    width: 100%;
    border-bottom: 1px solid #51525C;
    padding: 80px;

    @include for-mobile {
      padding: 48px 16px;
    }

    @include for-middle {
      padding: 48px 5%;
    }

    h1 {
      color: #FFF;
      font-family: 'Manrope';
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      text-align: start;
      margin: 0;
      margin-bottom: 32px;

        @include for-mobile {
            font-size: 32px;
        }
    }

    p {
      color: #FFF;
      font-family: 'Manrope';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: start;

        @include for-mobile {
            font-size: 14px;
        }
    }
  }

  .info__container:first-child {
    border-top: 1px solid #51525C;
  }

  .info__container_frame {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 9999px;
    color: var(--Neutrals-White, #FFF);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 6%;
    justify-content: center;
    margin: auto;
    border: 1px solid transparent;
    background: 
      linear-gradient(to right, #18181B, #18181B), 
      linear-gradient(to right, #A855F78F , #FFFFFF8F); 
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    @include flex(center,center);
    font-family: $font;

    @include for-mobile {
        width: 25%;
    }

    @include for-middle {
        width: 15%;
    }
  }