@import '../../styles/vars.scss';
@import '../../styles/theme.scss';

.orders {
    flex: 12;
    // border-radius: 32px;
    border: 1px solid var(--border-main);
    background: var(--background-1);
    @include flex(center, start);
    flex-direction: column;

    .orders_title {
        border-bottom: 1px solid var(--border-main);
        padding: 2.4vh;
        width: calc(100% - 4.8vh);
        @include flex(center, start);
        gap: 0.8vh;
        color: var(--nav-button-font-active);
        font-size: 1.6vh;
        font-weight: 500;
        line-height: 1.6vh;

        path {
            fill: var(--nav-button-font-active);
        }
    }

    .orders_table {
        width: calc(100% - 4.8vh);
        padding: 2.4vh;
        max-width: 100vw;
        @include flex(center, start);
        flex-direction: column;
        gap: 3.2vh;
        
        table {
            background-color: var(--background-1) !important;
        }

        th {
            border-bottom: 1px solid var(--border-main, #2D2E32) !important;
        }

        td {
            background-color: var(--background-1) !important;
            border-radius: 0 !important;
            border-bottom: 1px solid var(--border-main, #2D2E32) !important;
            
            span {
                border-radius: 4px !important;
            }
        }

        .table_header {
            width: 100%;
            @include flex(center, space-between);

            .tabs {
                width: 70vh;
                position: relative;
                background-color: #333;
                border-radius: 8px;
                padding: 0.2vh;
                gap: 0.4vh;
                @include flex(center, start);
                border: 1px solid var(--border-button, rgba(255, 255, 255, 0.08));
                background: var(--border-main, #2D2E32);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
            }

            .tab {
                @include flex(center, center);
                flex: 1;
                padding: 1vh 1.2vh;
                gap: 0.8vh;
                font-family: $font;
                border: none;
                color: #b3b3b3;
                font-weight: 500;
                cursor: pointer;
                transition: color 0.3s;
                text-wrap: nowrap;
                z-index: 1;
                background: var(--border-main, #2D2E32);
                overflow-wrap: break-word;
                white-space: normal;

                svg {
                    path {
                        color: var(--tabs-tab-active-color);
                    }

                    circle {
                        color: var(--tabs-tab-active-color);
                    }
                }

                &.active {
                    color: var(--font-mine);
                    background-color: var(--background-2, #242529);
                    border-radius: 4px;

                    svg {
                        path {
                            stroke: #FCFCFC;
                        }

                        circle {
                            stroke: #FCFCFC;
                        }
                    }
                }

                &:hover {
                    color: var(--font-mine);
                }
            }

            .background {
                position: absolute;
                top: 0.15vh;
                background-color: var(--buttons-alternative-switcher);
                border-radius: 8px;
                transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
                z-index: 0;
            }

            .right_side {
                @include flex(center, end);
                gap: 0.8vh;

                .search {
                    padding: 1.3vh;
                    @include flex(center, start);
                    gap: 0.8vh;
                    border-radius: 8px;
                    border: 1px solid var(--border-main, #26272B);
                    background: var(--input-bg, #131316);

                    input {
                        border: none;
                        outline: none;
                        background: none;
                        font-family: $font;
                        width: 23vh;
                        color: var(--font-mine);
                        font-size: 1.4vh;
                        font-weight: 500;
                        line-height: 1.6vh;
                    }
                }

                .select {
                    width: 12.8vh;

                    .custom_icon {
                        padding: 0.8vh;
                        @include flex(center, center);
                        border-left: 1px solid var(--background-2, #242529);

                        path {
                            stroke: var(--font-mine);
                        }
                    }

                    .custom_option {
                        font-size: 1.4vh;
                        line-height: 1.6vh;
                        padding: 0.8vh 0.4vh 0.8vh 0.8vh;
                        border-radius: 8px;
                        position: relative;

                        .option {
                            display: inline-flex;
                            align-items: center;
                            justify-content: start;
                            width: auto;
                            padding: 0.6vh 0.8vh;
                            border-radius: 8px;
                            color: var(--font-mine);
                        }
                    }

                    .custom_option_selected {
                        font-size: 1.4vh;
                        line-height: 1.6vh;
                        padding: 0.8vh 0.4vh 0.8vh 0.8vh;
                        border-radius: 8px;
                        position: relative;
                        background-color: var(--input-bg);

                        .option {
                            display: inline-flex;
                            align-items: center;
                            justify-content: start;
                            width: auto;
                            padding: 0.6vh 0.8vh;
                            border-radius: 8px;
                            color: var(--font-mine);
                        }
                    }

                    .custom_option:hover {
                        background-color: var(--input-bg);
                        color: var(--font-mine);
                    }
                }
            }
        }

        .table {
            width: 100%;

            .header_cell {
                display: flex;
                align-items: center;
                gap: 0.4vh;

                span {
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 2.4vh;
                    letter-spacing: 0.24px;
                }

                .header_name {
                    display: block !important;
                }

                .sort_icon {
                    @include flex(center, start);
                    flex-direction: column;
                    gap: 0.2vh;
                    padding: 0.3vh 0.5vh;

                    path {
                        stroke: var(--font-sub-font);
                    }
                }
            }
        }

        .mobile_transactions {
            display: none;
        }
    }
}

@media (max-width:600px) {
    .orders {
        border: none;
        background: none;
        margin: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .orders_title {
            border: none;
            background: none;
            padding: 0;
            width: 100%;
            margin-top: 12px;
            font-size: 16px;
            margin-left: 50px;

            span {
                font-size: 16px;
            }

            svg {
                width: 16px;
                height: 16px;
            }
        }

        .orders_table {
            margin-top: 24px;
            padding: 0;
            width: 90%;

            .table {
                table {
                    background-color: var(--background-1);
                }
            }



            .table_header {
                flex-direction: column;
                gap: 24px;
                width: 90%;
                display: flex;

                .filter {
                    @include flex(center, center);
                    padding: 12px;
                    border-radius: 8px;
                    border: 1px solid rgba(255, 255, 255, 0.08);
                    background: var(--button-alternative-default, #303034);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                    margin-left: 16px;
                }

                .tabs {
                    width: 110%;
                    background: var(--border-main, #2D2E32);
                }

                .right_side {
                    width: 115%;
                    display: flex; 

                    .search {
                        width: 90%;

                        svg {
                            width: 16px;
                            height: 16px;
                        }

                        input {
                            font-size: 14px;
                        }
                    }

                    .select {
                        display: none;
                    }
                }
            }
        }

        .table {
            display: none;
        }

        .mobile_transactions {
            width: 90%;
            display: flex !important;
            align-items: start;
            justify-content: start;
            flex-direction: column;

            .label {
                @include flex(center, start);
                gap: 8px;
                color: var(--nav-button-font-active);
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;

                path {
                    fill: var(--font-mine);
                }
            }

            .transactions_list {
                width: 100%;
                @include flex(center, start);
                flex-direction: column;
                gap: 8px;

                .transaction {
                    width: 100%;
                    padding: 24px;
                    @include flex(center, start);
                    flex-direction: column;
                    border-radius: 8px;
                    background: var(--background-1, #18181B);
                    gap: 16px;
                    border: 1px solid var(--border-main, #2D2E32);

                    hr {
                        width: 100%;
                        border: none;
                        border-bottom: 1px solid var(--border-main);
                    }

                    .type_status {
                        width: 100%;
                        @include flex(center, space-between);

                        .left_side {
                            @include flex(center, center);
                            gap: 8px;
                            color: var(--font-mine, #FCFCFC);
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16px;

                            img {
                                width: 24px;
                                height: 24px;
                            }

                            .type {
                                @include flex(center, center);
                                padding: 4px 12px;
                                border-radius: 8px;
                                background: rgba(246, 61, 104, 0.08);
                                color: var(--Other-Red, #F63D68);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                                letter-spacing: 0.24px;

                                &.buy {
                                    color: var(--Other-Green, #10B981);
                                    background: rgba(0, 180, 160, 0.08);
                                }

                                &.sell {
                                    background: rgba(246, 61, 104, 0.08);
                                    color: var(--Other-Red, #F63D68);
                                }

                                &.simulate {
                                    background: rgba(233, 119, 14, 0.08);
                                    color: #E9770E;
                                }
                            }

                            .leverage {
                                @include flex(center, center);
                                padding: 4px 12px;
                                border-radius: 8px;
                                background: rgba(233, 119, 14, 0.08);
                                color: #E9770E;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                                letter-spacing: 0.24px;
                                text-transform: uppercase;
                            }
                        }

                        .right_side {
                            @include flex(center, center);
                            gap: 8px;

                            .type {
                                @include flex(center, center);
                                padding: 4px 12px;
                                border-radius: 8px;
                                background: rgba(246, 61, 104, 0.08);
                                color: var(--Other-Red, #F63D68);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                                letter-spacing: 0.24px;

                                &.buy {
                                    color: var(--Other-Green, #10B981);
                                    background: rgba(0, 180, 160, 0.08);
                                }

                                &.sell {
                                    background: rgba(246, 61, 104, 0.08);
                                    color: var(--Other-Red, #F63D68);
                                }

                                &.simulate {
                                    background: rgba(233, 119, 14, 0.08);
                                    color: #E9770E;
                                }
                            }

                            .leverage {
                                @include flex(center, center);
                                padding: 4px 12px;
                                border-radius: 8px;
                                background: rgba(233, 119, 14, 0.08);
                                color: #E9770E;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                                letter-spacing: 0.24px;
                                text-transform: uppercase;
                            }

                        }
                    }

                    .account_amount {
                        width: 100%;
                        @include flex(center, space-between);

                        .account {
                            @include flex(start, start);
                            flex-direction: column;
                            gap: 4px;

                            label {
                                color: var(--font-sub-font, #51525C);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                            }

                            p {
                                color: var(--font-mine, #FCFCFC);
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 16px;
                                margin: 0;
                            }

                            .profit_loss {
                                @include flex(center, start);
                                gap: 8px;
                                color: var(--dark-700);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                                letter-spacing: -0.26px;

                                .take_profit {
                                    color: var(--Green, #10B981);
                                    font-size: 12px;
                                    font-weight: 600;
                                    line-height: 16px;
                                    letter-spacing: -0.26px;
                                }

                                .stop_loss {
                                    color: #F63D68;
                                    font-size: 12px;
                                    font-weight: 600;
                                    line-height: 16px;
                                    letter-spacing: -0.26px;
                                }
                            }
                        }

                        .amount {
                            @include flex(end, start);
                            flex-direction: column;
                            gap: 4px;

                            label {
                                color: var(--font-sub-font, #51525C);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                            }

                            p {
                                @include flex(center, center);
                                gap: 4px;
                                color: var(--Other-Green, #10B981);
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 16px;
                                margin: 0;

                                &.with {
                                    color: #E9770E;
                                }
                            }
                        }
                    }

                    .id_date {
                        width: 100%;
                        @include flex(center, space-between);

                        .id,
                        .date {
                            color: var(--font-mine, #FCFCFC);
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16px;
                            margin: 0;
                        }
                    }
                }

            }
        }
    }
}

.filter_drawer {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    background-color: var(--background-2);
    justify-content: space-between;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid var(--border-main, #2D2E32);

        .left_side {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;

            div {
                display: flex;
                padding: 8px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                background: var(--border-main, #2D2E32);
            }

            span {
                color: var(--font-mine, #FCFCFC);
                font-family: 'Inter';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.28px;
            }
        }

        .right_side {
            display: flex;
            padding: 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.08);
            background: var(--button-alternative-default, #303034);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
        }
    }

    .body {
        padding: 16px;
        gap: 16px;

        .select {
            margin-bottom: 16px;

            .custom_icon {
                padding: 0.8vh;
                // border-radius: 8px;
                // background-color: var(--input-dropdown-button);
                border-left: 1px solid var(--background-2, #242529);
                @include flex(center, center);

                path {
                    stroke: var(--font-mine);
                }
            }

            .custom_option {
                font-size: 1.4vh;
                line-height: 1.6vh;
                padding: 0.8vh 0.4vh 0.8vh 0.8vh;
                border-radius: 8px;
                position: relative;

                .option {
                    display: inline-flex;
                    align-items: center;
                    justify-content: start;
                    width: auto;
                    padding: 0.6vh 0.8vh;
                    border-radius: 8px;
                    color: var(--font-mine);
                }
            }

            .custom_option_selected {
                font-size: 1.4vh;
                line-height: 1.6vh;
                padding: 0.8vh 0.4vh 0.8vh 0.8vh;
                border-radius: 8px;
                position: relative;
                background-color: var(--input-bg);

                .option {
                    display: inline-flex;
                    align-items: center;
                    justify-content: start;
                    width: auto;
                    padding: 0.6vh 0.8vh;
                    border-radius: 8px;
                    color: var(--font-mine);
                }
            }

            .custom_option:hover {
                background-color: var(--input-bg);
                color: var(--font-mine);
            }
        }
    }

    .footer {
        padding: 16px;
        gap: 16px;
        display: flex;
        align-self: center;
        justify-content: center;
        border-top: 1px solid var(--border-main, #2D2E32);
        width: 90%;

        button {
            display: flex;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.24);
            background: var(--brand-mine, #3B82F6);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
            color: var(--text-mine, #FFF);
            text-align: center;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }
    }
}