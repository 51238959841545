@import '../../styles/vars.scss';

@mixin for-middle {
    @media screen and (min-width: 600.1px) and (max-width: 1200px) {
      @content;
    }
  }

@mixin for-mobile {
    @media screen and (max-width: 600px) {
      @content;
    }
  }

.tools_page{
    width: 100%;
    @include flex(center,start);
    flex-direction: column;
background-color: #242529;

    .header {
        width: 100%;
        @include flex(start, center);
        flex-direction: column;
        gap: 24px;
        padding: 80px;

        @include for-middle {
            justify-content: center;
            padding: 48px 5%;
        }
        
        @include for-mobile {
            justify-content: center;
            padding: 48px 16px;
        }

        h1 {
            color: #FFF;
font-family: 'Manrope';
font-size: 72px;
font-style: normal;
font-weight: 500;
line-height: 84px;
margin: 0;
            
            @include for-mobile {
                font-size: 40px;
                text-align: start;
            }
        }

        p {
            color: #FFF;
font-family: 'Manrope';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
margin: 0;
            text-align: start;

            @include for-mobile {
                width: 80%;
            }
        }
    }
    .body{
        @include flex(center,start);
        flex-direction: column;
        gap: 12px;
        padding: 0 80px;
        width: 100%;

        @include for-mobile {
            padding: 0;
        }

        @include for-middle {
            padding: 0 5%;
        }

        .tools_list{
            width: 100%;
            @include flex(stretch,space-between);
            gap: 12px;
            border-radius: 8px;
border: 1px solid rgba(255, 255, 255, 0.08);
background: #2D2E32;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

            @include for-middle {
                width: 100%;
                justify-content: start;
                max-width: 90vw;
                overflow-x: auto;
                height: 100%;
                gap: 8px;
            }

            .tool{
                padding: 16px;
                flex: 1;
                @include flex(center,start);
                flex-direction: column;
                cursor: pointer;
                margin: 4px;
                gap: 20px;
                &.active{
                    border-radius: 4px;
background: var(--Background-2, #242529);
                }

                h2{
                    width: 100%;
                    color: var(--Global-Dark-400, #A0A0AB);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    margin: 0;
                }
            }
        }
        .widget{
            width: 100%;
            min-height: 638px;
        }
    }
}
@media (max-width: 600px){
    .tools_page{
        .body{
            padding: 0 16px;
            .tools_list{
                width: 100%;
                justify-content: start;
                max-width: 90vw;
                overflow-x: auto; 
                height: 100%;
                gap: 8px;
                
                .tool{
                    padding: 16px;
                    min-width: 113px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: start;
                    gap: 12px;
                    &.active{
                        border-radius: 8px;
                    }
                    h2{
                        text-decoration: none;
                        font-size: 12px;
                    }
                }
            }
        }

    }
}