@mixin for-mobile {
    @media screen and (max-width: 600px) {
      @content;
    }
  }
  
  @mixin for-middle {
    @media screen and (min-width: 600.1px) and (max-width: 1200px) {
      @content;
    }
  }
  
  @mixin for-middle1 {
    @media screen and (min-width: 600.1px) and (max-width: 1000px) {
      @content;
    }
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    background: var(--Background-2, #242529);
    justify-content: space-between;
    width: 100%;

    .top {
        display: flex;
        justify-content: space-between;
        padding: 60px 80px;

        @include for-mobile {
            padding: 60px 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
        }

        span, a {
            color: #C7C9CA;
            text-align: center;
            font-family: 'Satoshi';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            text-decoration: none;
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        padding: 60px 80px;
        border-top: 1px solid var(--Border-1, #2D2E32);
        
        @include for-mobile {
            padding: 60px 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 59px;
        }

        .left_side {
            color: #C7C9CA;
            font-family: 'Satoshi';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        .right_side {
            display: flex;
            gap: 48px;

            @include for-mobile {
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
            }

            span {
                color: #FFF;
                font-family: 'Satoshi';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }
        }
    }
}