@import './vars.scss';
@import './theme.scss';

.paper {
    // padding: 9.2vh 1.6vh 1.6vh 6.2vh;
}

.account_drawer {
    width: calc(50.6vh - 3.6vh);
    background-color: var(--background-2);
    position: relative;
    // border-radius: 32px;
    border: 1px solid var(--border-main);
    @include flex(center, space-between);
    flex-direction: column;
    height: 100vh;

    .top_drawer{
        width: 100%;
        @include flex(center,start);
        flex-direction: column;
        .drawer_header {
            width: calc(100% - 3.2vh);
            border-bottom: 1px solid var(--border-main);
            padding: 1.6vh;
            color: var(--font-mine);
            font-size: 1.4vh;
            font-weight: 600;
            line-height: 1.6vh;
            letter-spacing: 0.28px;
            @include flex(center, space-between);
            gap: 1.2vh;

            .closeButton {
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.08);
                background: var(--button-alternative-default, #303034);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                padding: 12px;
                align-self: flex-end;
            }

            div {
                @include flex(center, center);
                gap: 12px;

                .icon {
                padding: 0.8vh;
                border-radius: 8px;
                @include flex(center, center);
                background: var(--border-main, #26272B);
    
                ellipse {
                    stroke: var(--font-mine);
                }
            }
            }
    
            
        }
        .drawer_body {
            width: calc(100% - 3.2vh);
            padding: 1.6vh;
            @include flex(center, start);
            flex-direction: column;
            gap: 2.4vh;
            .account_type {
                width: 100%;
                @include flex(center, center);
                gap: 0.8vh;
                border-radius: 8px;
                border: 1px solid var(--border-button, rgba(255, 255, 255, 0.08));
                background: var(--border-main, #2D2E32);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                padding: 4px;
    
                .type {
                    flex: 1;
                    padding: 1.6vh;
                    @include flex(center, center);
                    flex-direction: column;
                    gap: 1.6vh;
                    color: var(--font-mine);
                    font-size: 1.4vh;
                    font-weight: 600;
                    line-height: 1.6vh;
                    letter-spacing: 0.28px;
                    border-radius: 4px;
                    // border: 1px solid var(--border-main);
                    cursor: pointer;
                    path{
                        stroke: var(--font-mine);
                    }
                    circle{
                        stroke: var(--font-mine);
                    }
                    .path_fill{
                        stroke: none;
                        fill: var(--font-mine);
                    }
                    &.active{
                    border-radius: 4px;
                    background: var(--background-2, #242529);
                    }
                }
            }
            .label_input{
                width: 100%;
                @include flex(start,start);
                flex-direction: column;
                gap: 1.2vh;
                label{
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    letter-spacing: 0.24px;
                }
                input{
                    outline: none;
                    border-radius: 8px;
                    border: 1px solid var(--border-main);
                    background: var(--input-bg);
                    font-family: $font;
                    padding: 1.6vh 0.8vh 1.6vh 1.6vh;
                    width: calc(100% - 2.4vh - 2px);
                    color: var(--font-mine);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
            }
            .label_select{
                width: 100%;
                @include flex(start,start);
                flex-direction: column;
                gap: 1.2vh;

                label{
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    letter-spacing: 0.24px;
                }
                .select{
                    width: 100%;
                    .custom_icon{
                        padding: 0.8vh;
                        // background-color: var(--input-dropdown-button);
                        @include flex(center,center);
                        path{
                            stroke: var(--font-mine);
                        }
                    }
                    .custom_option{
                        font-size:1.4vh;
                        line-height:1.6vh;
                        padding:0.8vh 0.4vh 0.8vh 0.8vh;
                        border-radius:8px;
                        position: relative;
                        .option{
                            display: inline-flex;
                            align-items: center;
                            justify-content: start;
                            width: auto;
                            padding: 0.6vh 0.8vh;
                            border-radius: 8px;
                            color: var(--font-mine);
                        }
                    }
                    .custom_option_selected {
                        font-size:1.4vh;
                        line-height:1.6vh;
                        padding:0.8vh 0.4vh 0.8vh 0.8vh;
                        border-radius:8px;
                        position: relative;
                        background-color:var(--input-bg) ;
                        .option{
                            display: inline-flex;
                            align-items: center;
                            justify-content: start;
                            width: auto;
                            padding: 0.6vh 0.8vh;
                            border-radius: 8px;
                            color: var(--font-mine);
                        }
                    }
                    .custom_option:hover{
                        background-color:var(--input-bg) ;
                        color: var(--font-mine);
                    }
                }
            }
        }
    }
    .drawer_footer{
        width: calc(100% - 3.2vh);
        padding: 1.6vh;
        @include flex(center,center);
        gap: 0.8vh;
        border-top: 1px solid var(--border-main);
        div{
            flex: 1;
            @include flex(center,center);
            border-radius: 8px;
            background: var(--buttons-alternative-default);
            color: var(--nav-button-font-active);
            font-size: 1.4vh;
            padding: 1.6vh;
            font-weight: 500;
            line-height: 1.6vh;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
        }
        div:hover{
            background: var(--buttons-alternative-hover);
        }
        button{
            flex: 1;
            @include flex(center,center);
            border-radius: 8px;
            border: none;
            padding: 1.6vh;
            font-family: $font;
            color: var(--global-dark-25);
            font-size: 1.4vh;
            font-weight: 500;
            line-height: 1.6vh;
            background: var(--brand-mine, #3B82F6);
            cursor: pointer;
        }
        button:hover{
            transition: all 0.15s ease-in-out;
            background: var(--brand-mine, #3B82F6);
            box-shadow: 0px 0px 8px 2px rgba(105, 79, 214, 0.32);
        }
    }
}

@media (max-width: 600px) {
    .paper{
        padding: 0;
        width: 100%;
    }
    .account_drawer{
        width: calc(100% - 2px);
        .close_icon{
            display: none;
        }
    }
}